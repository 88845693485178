import {Component, OnInit} from '@angular/core';
import {NewsService} from '../../service/news.service';
import {News} from '../../shared/news';
import {AuthService} from '../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import {NewsFormDialogComponent} from './news-form-dialog/news-form-dialog.component';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.css']
})
export class NewsFeedComponent implements OnInit {

  public news: News[];
  public addNewsButton = false;

  constructor(private newsService: NewsService, public authService: AuthService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.news = this.newsService.getAll();

    this.newsService.fetch();

    this.newsService.modelEmitter.subscribe(
      (news: News[]) => {
        this.news = news;
      }
    );

    this.addNewsButton = this.authService.hasPermission(['manage_news']);
  }

  openForm(news: News = null) {
    const dialogRef = this.dialog.open(NewsFormDialogComponent, {
      data: news,
    });
  }

  // edit(news: News) {
  //   this.openForm(news);
  // }
  //
  // delete(news: News) {
  //   this.newsService.delete(news);
  // }



}
