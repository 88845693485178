import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../auth/auth.service';
import {Router} from '@angular/router';
import {User} from '../../../shared/user';
// https://code-maze.com/angular-material-navigation/ <-- i used this
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();

  profile: User = null;
  status = false;

  constructor(public auth: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.setProfile();

    this.auth.authChanged.subscribe(
      (user: User) => {
        this.profile = user;
      }
    );
  }

  toggleMenu() {

    if (window.innerWidth <= 767.98) {
      this.status = !this.status;
    } else {
      this.status = false;
    }


  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  onLogout() {
    this.auth.logout();
    this.auth.destoyApiToken();
    this.toggleMenu();

    // redirect to login
    this.router.navigate(['/login']);
  }

  isAuth() {
    return this.auth.isAuthenticated();
  }

  hasRole(roles: Array<string>) {
    return this.auth.hasRole(roles);
  }

  setProfile() {
    this.profile = this.auth.getProfile();
  }

}
