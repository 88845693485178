import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MemberService} from '../member.service';
import {Subscription} from 'rxjs';
import {Member} from '../../shared/member';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-member-detail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.css']
})
export class MemberDetailComponent implements OnInit, OnDestroy {
  selectedMember: Member = null;
  subscription: Subscription;
  memberIndex: number;

  constructor(private route: ActivatedRoute,
              public auth: AuthService,
              private memberService: MemberService,
              private router: Router) {
  }

  ngOnInit() {

    this.subscription = this.route.params.subscribe(
      (params: any) => {
        this.memberIndex = params['id'];
        this.selectedMember = this.memberService.getById(this.memberIndex);
      }
    );

    // if not loaded load members\
    // if not trainer or any role load only the members that is autorised
    if (this.auth.hasPermission(['manage_member'])) {
      if (this.memberService.getAll().length === 0) {
        this.memberService.fetchMembers();
      }
    } else {
      if (this.memberService.getAll().length === 0) {
        this.memberService.fetchMember(this.memberIndex);
      }
    }
    this.memberService.memberChanged.subscribe(
      (member: Member[]) => {
        this.selectedMember = this.memberService.getById(this.memberIndex);
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onDelete(member) {

    if (confirm('Weet u zeker dat u dit lid verwijderen')) {
      this.memberService.delete(member);
      this.router.navigate(['/member']);
    }
  }

}
