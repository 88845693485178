import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import {Member} from '../shared/member';
import {MemberService} from './member.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styles: [`@media (max-width: 767.98px) {
    .hide-sm {
      display: none
    }
  }

  .fab-button-left {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    padding-top: 3px;
  }  `]
})
export class MemberComponent implements OnInit {

  members: Member[] = null;
  dataSource = new MatTableDataSource<Member>(this.members);
  pairCount = 0;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(private authService: AuthService, public router: Router, private memberService: MemberService) {
  }

  ngOnInit() {

    console.log('selected club', this.authService.getSelectedClub());

    if (
      this.authService.getSelectedClub() == null ||
      this.authService.getSelectedClub() == '0' ||
      this.authService.getSelectedClub() == 'undefined'
    ) {
      this.router.navigate(['/club/select']);
    } else {
      this.dataSource = new MatTableDataSource<Member>(this.members);

      this.members = this.memberService.getAll();
      if (this.members !== null) {
        this.dataSource = new MatTableDataSource<Member>(this.members);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }

      this.memberService.fetchMembers();
      this.memberService.memberChanged.subscribe(
        (member: Member[]) => {
          this.members = member;
          this.dataSource = new MatTableDataSource<Member>(this.members);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      );

      if (this.memberService.pairRequest === null) {
        this.memberService.fetchPairRequest();

        this.memberService.memberPairChanged.subscribe((data: Member[]) => {
          this.pairCount = data.length;
        });
      } else {
        this.pairCount = this.memberService.pairRequest.length;
      }
    }
  }

  OnRowClick(row: Member) {
    this.router.navigate(['member', row.id]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportMembers(seperator: string) {
    this.memberService.fetchExport(Number(this.authService.getSelectedClub()), seperator).subscribe((csvData: any) => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([csvData], {type: "text/csv"});

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = "members.csv";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }

  _calculateAge(birthday) { // birthday is a date
    birthday = new Date(birthday);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
