<h1>Leden</h1>

<button mat-fab color="accent" class="fab-button-left" [routerLink]="['new']">
  <mat-icon aria-label="lid toevoegen">add</mat-icon>
</button>

<div fxLayout="row" fxLayoutAlign="space-between" [ngClass]="{'d-none d-md-block' :router.url != '/member'}">
  <div fxFlexAlign="start">
    <a mat-stroked-button [matBadge]="pairCount" [routerLink]="['/user/authorize-request']">Autorisatieverzoeken</a>
  </div>
  <div fxFlexAlign="end" class="end-buttons">

    <button mat-stroked-button [routerLink]="['/member/import']" style="margin-right: 5px;">Import</button>
    <button mat-stroked-button [matMenuTriggerFor]="appMenu" fxHide.lt-sm>Export</button>
  </div>
</div>


<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="exportMembers(';')">CSV (Windows)</button>
  <button mat-menu-item (click)="exportMembers(',')">CSV (Mac/linux)</button>
</mat-menu>


<div>
  <mat-form-field style="width: 100%">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
  <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> #</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Naam</th>
      <td mat-cell *matCellDef="let element"> {{element.first_name}} {{element.name}} </td>
    </ng-container>

    <!-- age Column -->
    <ng-container matColumnDef="age">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Leeftijd</th>
      <td mat-cell *matCellDef="let element">  {{_calculateAge(element.birthdate)}}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Lidmaatschap status</th>
      <td mat-cell *matCellDef="let element"> {{element.status.name}}</td>
    </ng-container>

    <!-- Rol Column -->
    <ng-container matColumnDef="role_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Rol</th>
      <td mat-cell *matCellDef="let element"> {{element.role[0].name}}</td>
    </ng-container>

    <!-- group Column -->
    <ng-container matColumnDef="group_id">
      <th mat-header-cell *matHeaderCellDef>Groep</th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let group of element.group">
          {{group.name}}
        </span>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="[ 'first_name','age','status_id','role_id','group_id']"></tr>
    <tr mat-row (click)="OnRowClick(row)"
        *matRowDef="let row; columns: ['first_name','age','status_id','role_id','group_id'];"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[25,50,100]" showFirstLastButtons></mat-paginator>
</div>
