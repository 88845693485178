<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Autorisatie verzoeken</mat-card-title>
  </mat-card-header>
  <mat-card-content >
    <p class="text-center">
      <span style="font-size: 35px" class="text-danger">{{pairRequestCount}}</span>
    </p>
    <br/>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button color="primary" [routerLink]="['/user/authorize-request']">goed-/afkeuren</button>
  </mat-card-actions>
</mat-card>

