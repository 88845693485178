import {Member} from "./member";
/**
 * Created by Patrick on 17-3-2017.
 */

export class User {
    public created_at: string;
    public updated_at: string;
    public member:Member[] = [];
    public password: string;

    constructor(public id:number, public first_name: string, public name: string, public email: string, ) {}

}
