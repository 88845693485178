<mat-card>
  <h2 *ngIf="router.url.includes('new')">Nieuwe training</h2>
  <h2 *ngIf="router.url.includes('edit') ">Bewerk training</h2>

  <form #form="ngForm" (ngSubmit)="onSubmit(form.value)" id="trainingForm" fxLayout="column" fxLayoutGap="10px">
    <ngx-material-timepicker #picker></ngx-material-timepicker>
    <ngx-material-timepicker #picker_end></ngx-material-timepicker>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxFill >
      <mat-form-field fxFlex="40" fxFlex.lt-md="100" >
        <input matInput type="date" id="date" placeholder="Datum" name="date" [ngModel]="training.date"
               required>
      </mat-form-field>
      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
        <input *ngIf="checkSupportTimeInput()" matInput type="time" id="time" placeholder="Start tijd"  name="time_start"
               [ngModel]="training.time_start">
        <input *ngIf="checkSupportTimeInput() === false" matInput type="time" id="time_start_picker" placeholder="Start tijd" [format]="24" [ngxTimepicker]="picker" name="time_start"
               [ngModel]="training.time_start">
      </mat-form-field>


      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
        <input *ngIf="checkSupportTimeInput()" matInput type="time" id="time_end" placeholder="Eind tijd" name="time_end"
               [ngModel]="training.time_end">
        <input *ngIf="checkSupportTimeInput() === false" matInput type="time" id="time_end_picker" placeholder="Eind tijd" name="time_end"
               [ngModel]="training.time_end" [format]="24" [ngxTimepicker]="picker_end">
      </mat-form-field>
    </div>

    <mat-form-field>
      <input matInput type="number" id="max_participants" placeholder="maximaal aantal aanwezigen" name="max_participants"
             [ngModel]="training.max_participants" >
      <mat-hint>Veld leeg= geen maximum. Trainers tellen ook mee</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <input matInput type="text" id="short_description" placeholder="Training soort" name="short_description"
             [ngModel]="training.short_description" required>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <textarea matInput type="text" id="description" placeholder="Omschrijving en/of trainingschema"
                name="description" [ngModel]="training.description"></textarea>
    </mat-form-field>


    <div>
      <label>Aanwezige trainer</label>

      <mat-list>
        <span *ngFor="let checkbox of checkboxes" [class]="checkbox.checked ? 'selected' : ''">
            <mat-list-item>
              <mat-checkbox class="example-margin"
                            [(ngModel)]="checkbox.checked" [ngModelOptions]="{standalone: true}">{{checkbox.label}}</mat-checkbox>
              <mat-divider></mat-divider>
            </mat-list-item>

          </span>
      </mat-list>
    </div>

    <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">Opslaan</button>
  </form>
</mat-card>
