import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Training} from '../training';
import {TrainingService} from '../training.service';
import {Member} from '../../shared/member';
import {File} from '../../shared/file';
import {AuthService} from '../../auth/auth.service';
import {FileService} from '../../service/file-service.service';


@Component({
  selector: 'app-training-detail',
  templateUrl: './training-detail.component.html',
  styleUrls: ['./training-detail.component.css']
})
export class TrainingDetailComponent implements OnInit, OnDestroy {
  selectedTraining: Training;
  subscription: Subscription;
  trainingIndex: number;
  downloadFileModel: File;
  members: Member[];
  trainers: Member[];


  constructor(private route: ActivatedRoute,
              private trainingService: TrainingService,
              private authService: AuthService,
              private fileService: FileService,
              public auth:AuthService,
              private router: Router) {
  }

  ngOnInit() {

    this.members = [];
    this.trainers = [];

    this.subscription = this.route.params.subscribe(
      (params: any) => {
        this.trainingIndex = params['id'];
        //document.getElementById('training-detail').scrollIntoView();
        this.selectedTraining = this.trainingService.getTraining(this.trainingIndex);
        this.preareTraining(this.selectedTraining);
      }
    );

    this.trainingService.trainingChanged.subscribe(
      (training: Training[]) => {
        this.selectedTraining = this.trainingService.getTraining(this.trainingIndex);
        this.preareTraining(this.selectedTraining);
      }
    );
  }

  preareTraining(training: Training) {
    this.members = [];
    this.trainers = [];
    console.log('prepaire', training);
    if (training && training.members && training.members.length > 0 && training.members[0].pivot) { // if training has set but is empty when reloading page
      this.members = training.members.filter(member => (member.pivot.trainer === 0));
      this.trainers = training.members.filter(member => (member.pivot.trainer === 1));
    } else if (training) {
      this.members = training.members;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onDelete(training: Training) {

    if (confirm('Weet u zeker dat u de training wil verwijderen?')) {
      this.trainingService.destroy(training);
      this.router.navigate(['/training']);
    }
  }

  removeMember(member: Member) {
    console.log('remove member', member);

    if (confirm('weet u zeker dat ' + member.first_name + ' niet mee traint?')) {
      console.log(this.selectedTraining.members);
      this.trainingService.removeMember(this.selectedTraining, member);
      // this.selectedTraining.members.splice(this.selectedTraining.members.indexOf(member), 1);
    }
  }

  downloadFile(event, file: File) {
    event.preventDefault();
    this.downloadFileModel = file;

    this.trainingService.fetchFile(
      Number(this.authService.getSelectedClub()),
      file.id
    ).subscribe((fileObject: any) => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([fileObject], {type: this.downloadFileModel.mime_type});

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      const link = document.createElement('a');
      link.href = data;
      link.download = this.downloadFileModel.name;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

      setTimeout(function() {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);

      this.downloadFileModel = null;
    });
  }

  handleFileInput(files: FileList) {
    if (files.length > 0) {

      const uploadfile = files.item(0);

      this.fileService.create(uploadfile).subscribe(
        (file: File) => {
          this.selectedTraining.files.push(file);

          console.log(this.selectedTraining);

          this.trainingService.edit(this.selectedTraining, this.selectedTraining);
        }
      );
    }
  }

  deleteFile(file: File) {
    if (confirm('Weet je zeker dat je dit document wil verwijderen?')) {
      this.fileService.delete(file).subscribe(
        (object: any) => {
          console.log(this.selectedTraining.files.findIndex(x => x.id === file.id));

          this.selectedTraining.files.splice(
            this.selectedTraining.files.findIndex(x => x.id === file.id),
            1
          );
        }
      );
    }
  }

  //@TODO temporary for conona virus
  isAgeUnderTwelve(element, index, array) {
    return (element.age <= 12);
  }

  isAgeaboveTwelve(element, index, array) {
    return (element.age >= 13);
  }

}
