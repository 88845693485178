import {Injectable} from '@angular/core';
import {AppService} from "../app.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class SwimTimesService extends AppService {

    constructor(http: HttpClient) {
        super(http);
    }

    getPersonalRecords(swimTimesId:number) {
        return this.apiGet('swimtimes/'+swimTimesId)
    }

    getStyleStastic(swimTimesId:number, styleId:number, courseId:number) {
        return this.apiGet('swimtimes/stylestatistic/'+swimTimesId+"/"+styleId+"/"+courseId);
    }

}
