import {EventEmitter, Injectable} from '@angular/core';
import {News} from "../shared/news";

@Injectable({
  providedIn: 'root'
})
export class ValidationErrorService {

  public validationMessage:{};
  public validationEmitter = new EventEmitter<{}>();

  constructor() { }

  public addValdationMessages(object) {
    this.validationMessage = object;
    this.validationEmitter.emit(object);
  }

  public has(key:string) {
      if (this.validationMessage[key]) {
        return this.validationMessage[key];
      }

      return false;
  }
}
