<!--<h1 class="page-header">Welkom {{user.first_name}} {{user.name}}</h1>-->

<div class="col-md-12">
  <!-- <i title="vernieuwen" (click)="renewProfile()" class="material-icons text-info">autorenew</i> -->

  <div *ngIf="user != null && user?.member.length > 0 && user?.member != null" fxLayout="row" fxLayoutGap="5px"
       style="margin-top: 10px; overflow-x: auto">
    <div class="bottom-buffer" *ngFor="let member of user.member">
      <app-pressent-charts *ngIf="member.pivot.authorised == 1"
                           [member]="member" [profileUrl]="true" [showdayChart]="false"></app-pressent-charts>

      <mat-card class="bottom-buffer" *ngIf="member.pivot.authorised == null" >
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{member?.first_name}} {{member?.name}}
          </mat-card-title>
          <mat-card-subtitle>Aanwezigheid per dag</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 175px" >
          <p>Wachten op autorisatie van de zwemclub...</p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button color="accent" disabled>Zwemmer details</button>
        </mat-card-actions>
      </mat-card>

    </div>
    <div style="border: 1px solid #3f51b5; width: 300px; height:325px;" class="text-center"
         fxLayoutAlign="space-around center">
      <a style="width: 250px" [routerLink]="['/user/connect']"><i class="material-icons h1">add_circle_outline</i><br/>Lid
        koppeling toevoegen</a>
    </div>
  </div>

</div>
<hr/>
<div class="row">
  <div class="col-md-9 order-12">

    <app-news-feed *ngIf="selectedClub != null"></app-news-feed>

    <mat-card *ngIf="selectedClub == undefined || selectedClub == null">
      <mat-card-title>
        Selecteer club nieuws
      </mat-card-title>
      <mat-card-content>
        Selecteer club waarvan nieuws weergegeven moet worden
      </mat-card-content>
      <mat-card-actions>
        <button mat-button color="primary" [routerLink]="['/club/select']">Selecteer club</button>
      </mat-card-actions>
    </mat-card>

  </div>
  <div class="col-md-3 order-0 order-md-12">

    <mat-card class="bottom-buffer" *ngIf="PWAPrompt != undefined">
      <mat-card-header>
        <mat-card-title>swimhub installeren</mat-card-title>
        <mat-card-subtitle>maak het makkelijker toegankelijk</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>Instaleer de Web app nu met 1 druk op je mobiel</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button color="warn" (click)="install()">Instaleer</button>
      </mat-card-actions>
    </mat-card>


    <!--toevoegen van autorisatieverzoeken-->
    <div *ngIf="authService.hasPermission(['manage_member'])">
      <app-pair-request-card class="bottom-buffer"></app-pair-request-card>
    </div>
  </div>
</div>


