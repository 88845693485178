import {Component, isDevMode, OnInit} from '@angular/core';
import {AuthService} from "../auth.service";
import {Router} from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    validCredentials: boolean = true;
    localStorageSupport: boolean = true;
    email: string = null;
    submit:boolean = false;
    devMode:boolean = isDevMode()

    redirectTo = ['/dashboard'];

    constructor(private auth: AuthService, private router: Router,private snackbar:MatSnackBar) {
    }

    ngOnInit() {
        if (typeof(Storage) !== "undefined") {
            // Code for localStorage/sessionStorage.

            if (localStorage.getItem("_a") !== null) {
               //api key exist

                //check api key is valid

                //redirect to next page
                this.router.navigate(this.redirectTo);
            }
        } else {
            // Sorry! No Web Storage support..
            this.localStorageSupport = false;
        }
    }

    onSubmit(form: any) {
        this.submit = true;
        //send to api
        this.auth.login(form.value.email,form.value.password).subscribe(
            (data: any) => {
                console.log(data);
                this.validCredentials = true;
                this.auth.storeApiToken(data.api_token);
                this.auth.storeUser(data.user);
                this.auth.storeProfile(data.user);
                this.snackbar.open("Login Geslaagd");


                this.router.navigate(this.redirectTo);

            },
            (error: any) => {
                console.log(error,error);
                this.validCredentials = false;
                this.email = form.value.email;
                this.snackbar.open("Login Geslaagd",null,{duration:10000});

                this.submit = false;
            }
        );
    }

}
