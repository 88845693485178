import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-start',
  template: `
    <p>
      Selecteer een training
    </p>
  `,
  styles: []
})
export class TrainingStartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
