<mat-nav-list>
  <a mat-list-item
     (click)="onSidenavClose()"
     routerLink="/dashboard"><i class="material-icons">home</i>dashboard</a>
  <a mat-list-item
     *ngIf="isAuth() && hasRole(['Administrator'])"
     (click)="onSidenavClose()"
     routerLink="/user"><i class="material-icons">contacts</i>Gebruikers</a>
  <a mat-list-item *ngIf="isAuth() && auth.hasPermission(['show_training'])" (click)="onSidenavClose()" routerLink="/training"><i class="material-icons">pool</i>Trainingen</a>
  <a mat-list-item *ngIf="isAuth() && auth.hasPermission(['manage_member'])"  (click)="onSidenavClose()" routerLink="/member"><i class="material-icons">group</i>Leden</a>
  <a mat-list-item (click)="onSidenavClose()" routerLink="/club/schedule"><i class="material-icons">calendar_today</i>Kalender</a>
  <a mat-list-item *ngIf="isAuth() && auth.hasPermission(['manage_meet'])"  (click)="onSidenavClose()" routerLink="/meet"><i class="material-icons">gps_fixed</i>Wedstrijden</a>
  <a mat-list-item *ngIf="isAuth() && auth.hasPermission(['show_group'])"  (click)="onSidenavClose()" routerLink="/group"><i class="material-icons">group_work</i>Groepen</a>
<!--
  <a mat-list-item routerLink="/owner" (click)="onSidenavClose()">
    <mat-icon>assignment_ind</mat-icon> <span class="nav-caption">Owner Actions</span>
  </a>
  <a mat-list-item routerLink="#" (click)="onSidenavClose()">
    <mat-icon>account_balance</mat-icon><span class="nav-caption">Account Actions</span>
  </a>-->
</mat-nav-list>
