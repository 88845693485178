
<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{selectedTraining?.date |date}}
    </mat-card-title>
    <mat-card-subtitle>{{selectedTraining?.short_description}}</mat-card-subtitle>
  </mat-card-header>
  <h2>Selecteer aanwezige deelnemers</h2>

  <form #form="ngForm" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutGap="10px">

    <mat-form-field appearance="fill">
      <mat-label>Leden groep</mat-label>
      <mat-select (selectionChange)="filterGroup(groupFilter.value)" #groupFilter>
        <mat-option value="0"> Alles</mat-option>
        <mat-option *ngFor="let group of groups" [value]="group.id">
          {{group.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput type="search" (keyup)="0" value="" placeholder="Zoeken..." name="search" #search>
    </mat-form-field>

    <mat-list>
        <span *ngFor="let checkbox of checkboxes |filterMembers:search.value " [class]="checkbox.checked ? 'selected' : ''">
            <mat-list-item>
              <mat-checkbox class="example-margin" name="members{{checkbox.value}}" value="{{checkbox.value}}"
                            [(ngModel)]="checkbox.checked">{{checkbox.name}}</mat-checkbox>
              <mat-divider></mat-divider>
            </mat-list-item>

          </span>
    </mat-list>

    <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">Opslaan</button>
  </form>
</mat-card>
