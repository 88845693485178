import {Component, OnInit, Input, OnChanges} from '@angular/core';
import {SwimTimesService} from "../swim-times.service";
import {Member} from "../../shared/member";

@Component({
    selector: 'app-swimtimes-record',
    templateUrl: 'personal-record.component.html',
    styleUrls: ['personal-record.component.css']
})
export class PersonalRecordComponent implements OnInit, OnChanges {
    @Input() swimTimesId: number;
    @Input() memberIndex: number;
    records = [];

    ngOnChanges(changes: any) {
        //console.log(changes);
        this.readRecords();
    }

    constructor(private swimTimesService: SwimTimesService ) {
    }

    ngOnInit() {
        this.readRecords();
    }

    readRecords() {
        this.records = [];
        if (this.swimTimesId != null) {
            this.swimTimesService.getPersonalRecords(this.swimTimesId).subscribe(
                (data: any) => {
                    this.records = data;
                }
            );
        }
    }

}
