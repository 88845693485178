import {Component, OnInit} from '@angular/core';
import {MemberService} from '../../member/member.service';
import {Member} from '../../shared/member';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-pair-request-card',
  templateUrl: './pair-request-card.component.html',
  styleUrls: ['./pair-request-card.component.css']
})
export class PairRequestCardComponent implements OnInit {

  pairRequestCount = 'laden...';

  constructor(private memberService: MemberService, private authService: AuthService) {
  }

  ngOnInit() {
    if (this.memberService.pairRequest === null && this.authService.getSelectedClub() !== null) {
      this.memberService.fetchPairRequest();

      this.memberService.memberPairChanged.subscribe((data: Member[]) => {
        this.pairRequestCount = data.length.toString();
      });
    } else {
      this.pairRequestCount = this.memberService.pairRequest.length.toString();
    }
  }

}
