<mat-card class="bottom-buffer" *ngIf="addNewsButton">

  <mat-card-content>
    <a (click)="openForm()">
      <mat-icon style="height: auto" aria-hidden="false" aria-label="Example home icon">add</mat-icon>
      Voeg nieuw bericht toe
    </a>
  </mat-card-content>
</mat-card>
<!--@todo add message for club selector -->
<div *ngIf="authService.hasPermission(['show_news'])">
  <div *ngFor="let article of news ">
    <app-news-item [article]="article"></app-news-item>
  </div>
</div>
