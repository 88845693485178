<mat-card class="example-card bottom-buffer" style="margin: 0 auto; margin-bottom: 10px">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="10px">
      <img *ngIf="article.image != null" style="width: 150px; height: 150px" src="{{article.image}}"
           alt="Photo of {{article.title}}">
      <p>
        <mat-card-title>{{article.title}}
          <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="edit(article)">Edit</button>
            <button mat-menu-item (click)="delete(article)">Delete</button>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="appMenu" *ngIf="newsMenuVisable">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-card-title>
        <mat-card-subtitle>Geplaast op: {{article.updated_at |date}}</mat-card-subtitle>
        <markdown [data]="article.body"></markdown>
      </p>
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button matBadge="{{article.comments.length}}" color="accent" (click)="openComments()">Reacties</button>
  </mat-card-actions>
  <mat-card-footer class="container" *ngIf="showComments">

    <mat-card *ngFor="let comment of article.comments" class="bottom-buffer" >
      <mat-card-subtitle>
        {{comment.user.first_name}} {{comment.user.name}}<br/>
        <small>{{comment.created_at}}</small>
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="deleteComment(comment)">Delete</button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="appMenu" *ngIf="showMenu(comment,comment.user_id)">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-card-subtitle>
      <mat-card-content>
        {{comment.comment}}
      </mat-card-content>
    </mat-card>

    <span *ngIf="article.comments.length == 0">
      Nog geen reactie geplaats
    </span>

    <hr/>
    <!--add comment-->
    <mat-form-field class="full-width">
      <input matInput type="text" placeholder="Uw reactie" [(ngModel)]="comment.comment" required/>
    </mat-form-field>
    <br/>
    <button type="button" mat-button mat-raised-button color="primary" (click)="saveComment()">Verstuur</button>

  </mat-card-footer>
</mat-card>
