/**
 * Created by Patrick on 17-3-2017.
 */
import {Member} from './member';

export class Group {
  public created_at: string;
  public updated_at: string;
  public id: number;
  public club_id: number;
  public name: string;
  public description: string;
  public color: string;
  public members: Member[] = [];

  constructor() {
  }

}
