

<div >
  <div fxLayout="row" fxLayout.lt-md="column" fxFlexFill fxLayoutGap="5px">
    <div [fxFlex]="showBoth()" fxFlex.lt-md="100">
      <mat-card class="bottom-buffer" *ngIf="showdayChart">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{member?.first_name}} {{member?.name}}
          </mat-card-title>
          <mat-card-subtitle>Aanwezigheid per dag</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div style="display: block">
            <canvas max-width="500px" max-height="500px" baseChart
                    [data]="dayChartData"
                    [labels]="dayChartLabels"
                    [legend]="false"
                    [chartType]="dayChartType"></canvas>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button color="accent" *ngIf="profileUrl" [routerLink]="['/member/'+member.id]">Zwemmer
            details
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div [fxFlex]="showBoth()" fxFlex.lt-md="100" >
      <mat-card class="bottom-buffer" *ngIf="showMonthChart">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{member?.first_name}} {{member?.name}}
          </mat-card-title>
          <mat-card-subtitle>Aanwezigheid per maand</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div style="display: block">
            <canvas baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [chartType]="barChartType"
            ></canvas>
            Getraind per week
            <small>(afgelopen 10 weken)</small>
            : {{average}}
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button color="accent" *ngIf="profileUrl" [routerLink]="['/member/'+member.id]">Zwemmer
            details
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
