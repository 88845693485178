import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Training} from '../training';
import {TrainingService} from '../training.service';
import {MemberService} from '../../member/member.service';
import {Member} from '../../shared/member';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

@Component({
  selector: 'app-training-form',
  templateUrl: './training-form.component.html',
  styleUrls: ['./training-form.component.css']
})
export class TrainingFormComponent implements OnInit, OnDestroy {

  trainingIndex: number;
  training: Training;
  isNew = true;
  subscription: Subscription;
  subcriptionTraining: Subscription;
  trainers: Member[];
  public checkboxes = [];
  public trainingSubscription: Subscription;


  constructor(private activeRouter: ActivatedRoute,
              private trainingService: TrainingService,
              public router: Router,
              private memberService: MemberService) {

    const date = new Date().toISOString().split('T')[0];

    this.training = new Training(date, '', '', []);

  }

  ngOnInit() {
    document.getElementById('trainingForm').scrollIntoView();
    this.subscription = this.activeRouter.params.subscribe(
      (params: any) => {
        if (params['id']) {

          // find selected trainer

          this.isNew = false;
          this.trainingIndex = +params['id'];
          this.training = this.trainingService.getTraining(this.trainingIndex);
          //console.log('test', this.training);
        } else {
          console.log('test', this.training);
          this.isNew = true;
          //this.training = null;
        }
      }
    );

    this.memberService.fetchMembers();

    this.memberService.memberChanged.subscribe(
      (members: Member[]) => {
        this.trainers = members.filter(this.filterTrainer);

        let selectedTrainers = [];

        // selected that trainer is in training
        if (this.isNew === false && this.training.members) {
          selectedTrainers = this.training.members.filter(member => (member.pivot.trainer === 1));
        }


        // prepaire checkboxes
        for (const member of this.trainers) {
          this.checkboxes.push({
            label: member.first_name + ' ' + member.name,
            value: member.id,
            member,
            checked: selectedTrainers.filter(trainer => (trainer.id === member.id)).length > 0
          });
        }
      });
  }

  filterTrainer(element, index, array) {
    for (const role of element.role) {
       // element.role is array of roles. find the role with trainer in array
        if (role.name.toLowerCase() == 'trainer') {
          return true;
        }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmit(training: Training) {

    // save trainers...
    const filter = this.checkboxes.filter(checkbox => (checkbox.checked === true));
    // filter selected trainer
    const selectedTrainers = [];
    for (const checkbox of filter) {
      selectedTrainers.push(checkbox.member);
    }
    training.trainer = selectedTrainers;

    if (this.isNew) {
      this.trainingService.create(training);
    } else { // edit
      this.trainingService.edit(this.training, training);
    }

    this.subcriptionTraining = this.trainingService.trainingChanged.subscribe(
      (training: Training[]) => {
        this.subcriptionTraining.unsubscribe();
        if (this.isNew === false) {
          this.router.navigate(['/training', this.trainingIndex]);
        } else {
          this.router.navigate([`/training/0/member`]);
        }
      }
    );


    if (this.isNew === false) {
      this.router.navigate(['/training', this.trainingIndex]);
    } else {
      this.router.navigate(['/training', 0]);
    }

  }

  checkSupportTimeInput() {

    let support:boolean ;

    try {
      var input = document.createElement("input");

      input.type = "time";

      if (input.type === "time") {
        support = true;
      } else {
        support = false;
      }
    } catch(e) {
      support = false;
    }

    if(support === false) {
      this.training.time_start = this.training.time_start.slice(0,-3)
      this.training.time_end = this.training.time_end.slice(0,-3)
    }

    return support;

  }




}
