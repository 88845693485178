/**
 * Created by Patrick on 17-3-2017.
 */
import {User} from './user';

export class Comment {
  public created_at: string;
  public updated_at: string;
  public id: number;
  public user_id: number;
  public user: User
  public commentable_id: number;
  public commentable_type: string;


  constructor(public comment: string) {
  }

}
