import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {HelperService} from './helper.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {File} from '../shared/file';

@Injectable({
  providedIn: 'root'
})
export class FileService implements ServiceInterface {

  constructor(private http: HttpClient, private  helper: HelperService, private  authService: AuthService, private snackbar: MatSnackBar) {
  }

  create(object: any) {
    const formData: FormData = new FormData();
    formData.append('file', object, object.name);

    return this.http.post<File>(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/file`, formData);
  }

  delete(object: any) {
    return this.http.delete(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/file/${object.id}`);
  }

  fetch() {
  }

  get(id: number) {
  }

  getAll() {
  }

  update(object: any) {
  }
}
