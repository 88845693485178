import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HelperService} from './helper.service';
import {Style} from '../shared/style';

@Injectable({
    providedIn: 'root'
})
export class StyleService implements ServiceInterface {

    private styles: Style[] = [];
    public styleEmitter = new EventEmitter<Style[]>();

    constructor(private http: HttpClient, private  helper: HelperService) {

    }

    create(object: Style) {
        this.http.post(this.helper.APIUrl + 'style', object, this.helper.httpOptions).subscribe(
            (objects: Style) => {
                this.styles.push(objects);
                this.styleEmitter.emit(this.styles);
            }
        );
    }

    delete(object: any) {
    }

    fetch() {
        this.http.get<Style[]>(this.helper.APIUrl + 'style', this.helper.httpOptions).subscribe(
            (objects: Style[]) => {
                this.styles = objects;
                this.styleEmitter.emit(this.styles);
            }
        );
    }

    get(id: number) {
        return this.styles[id];
    }

    getAll() {
        return this.styles;
    }

    update(object: any) {
        this.http.patch(this.helper.APIUrl + 'style/' + object.id, object, this.helper.httpOptions).subscribe(
            (newObjects: Style) => {
                this.styles[this.styles.indexOf(object)] = newObjects;
                this.styleEmitter.emit(this.styles);
            }
        );
    }



}
