<div class="col-sm-6 offset-md-3">
    <h1 class="text-center">Login</h1>

    <div class="alert alert-danger" role="alert" *ngIf="!localStorageSupport" >
        <strong>Browser niet ondersteund!</strong> Uw browser is niet ondersteund om deze applicatie te kunnen werken <a target="_blank" href="http://browsehappy.com">Update nu uw browser</a>
    </div>

    <a href="https://api.swimhub.nl/oauth/authorize?client_id=1&redirect_url=https://swimhub.nl&response_type=code" class="btn btn-lg btn-block btn-success">Inloggen via Swimhub API</a>

    <a href="http://localhost:8000/oauth/authorize?client_id=1&redirect_url=http://localhost:4200&response_type=code" class="btn btn-lg btn-block btn-warning" *ngIf="devMode">Inloggen local via Swimhub API</a>


    <!--
    <div class="alert alert-danger" role="alert" *ngIf="!validCredentials">
        <strong>Oh nee!</strong> Email adres en/of wachtwoord klopt niet. controleer gegevens en probeer het nog een
        keer
    </div>

    <form #form="ngForm" (ngSubmit)="onSubmit(form)">
        <div class="form-group">
            <label for="email">Email adres</label>
            <input type="email" class="form-control" id="email" placeholder="Emailadres" name="email" [ngModel]="email" required email>
            <div class="help-block text-muted">Geen account <a [routerLink]="['/register']" >registreer</a></div>
        </div>
        <div class="form-group">
            <label for="password">Wachtwoord</label>
            <input type="password" class="form-control" id="password" placeholder="Wachtwoord" name="password" ngModel
                   required minlength="6">
            <div class="help-block text-muted"> <a [routerLink]="['/forgot-password']" >Wachtwoord vergeten?</a></div>
        </div>
        <button type="submit" class="btn btn-block btn-primary" [disabled]="!form.valid">Inloggen</button>
    </form>
    <div *ngIf="submit">
        <app-bar-loader></app-bar-loader>
    </div>
    -->
</div>
