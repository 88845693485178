<h2>Add record</h2>

<form #form="ngForm" (ngSubmit)="onSubmit(form.value)">
    <mat-form-field class="form-control">
        <input type="date" name="date" matInput placeholder="Datum" [ngModel] required>
    </mat-form-field>

  <mat-form-field class="form-control">
      <input type="text" matInput placeholder="Zwem stijl" [formControl]="styleControl" [matAutocomplete]="auto"
             [ngModel] required>
      <mat-icon matSuffix (click)="DialogAddStyle()">add</mat-icon>
  </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let style of filteredOptions | async" [value]="style">{{style.distance}}M {{style.stroke_name}}</mat-option>
  </mat-autocomplete>

    <mat-form-field class="form-control">
        <input type="text" [mask]="currentMask" name="record" matInput #record_input
               [ngModel] required>
        <mat-placeholder class="placeholder" #record_placeholder>Record (hh:mm:ss:ms)</mat-placeholder>
    </mat-form-field>

    <div *ngIf="currentMask == '00:00:00.00'">
        <hr>
        <p>Split times</p>
      <div>
        <mat-form-field
          class="form-control"
          *ngFor="let splitCtrl of splitRecordArray.controls; let i = index"
        >
            <input matInput type="text" [mask]="currentMask" [formControl]="splitCtrl"
                   placeholder="split tijd (hh:mm:ss:ms)">
            <mat-icon (click)="onDeleteSplitTime(i)" matSuffix >delete</mat-icon>
        </mat-form-field>
      </div>
        <p>
            <button type="button" class="btn btn-sm btn-success" (click)="onAddSplitTime()">Voeg Split Tijd toe</button>
        </p>

    </div>


    <button type="submit" class="btn btn-block btn-success" [disabled]="!form.valid">Opslaan</button>

</form>
