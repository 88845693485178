import {Component, OnDestroy, OnInit} from '@angular/core';
import {Member} from '../../shared/member';
import {ActivatedRoute, Router} from '@angular/router';
import {MemberService} from '../member.service';
import {Subscription} from 'rxjs';
import {Role} from '../../shared/role';
import {RoleService} from '../../roles/role.service';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-member-form',
  templateUrl: './member-form.component.html',
  styleUrls: ['./member-form.component.css']
})
export class MemberFormComponent implements OnInit, OnDestroy {

  isNew = false;
  searchValue = '';
  selectedMember: Member;
  subscription: Subscription;
  memberSubscription: Subscription = null;
  memberIndex: number;

  roles: Role[] = [];
  searchResults = [];
  statusValue = '1';

  constructor(private route: ActivatedRoute,
              private memberService: MemberService,
              private roleService: RoleService,
              public  auth: AuthService,
              private router: Router) {
    this.selectedMember = new Member('', '', '');
  }

  ngOnInit() {
    this.subscription = this.route.params.subscribe(
      (params: any) => {
        this.memberIndex = params['id'];


        if (params.hasOwnProperty('id')) {
          this.isNew = false;
          this.memberIndex = +params['id'];
          this.selectedMember = this.memberService.getById(this.memberIndex);

          console.log(this.selectedMember);
        } else {
          this.isNew = true;
          //this.selectedMember = null;
        }

      }
    );


    if (this.selectedMember == null) { // if trainings are not loaded in service subscripe for changes
      this.memberService.memberChanged.subscribe(
        (member: Member[]) => {
          this.selectedMember = this.memberService.get(this.memberIndex);
          //console.log(this.selectedMember);
        }
      );
    }

    // fetch roles
    this.roleService.fetch();

    this.roleService.roleChanged.subscribe(
      (roles: Role[]) => {

        this.roles = roles;

        if(this.isNew == false && this.selectedMember.role != undefined && this.selectedMember.role.length > 0 ) {
          this.selectedMember.role_id = this.selectedMember.role[0].id;
        } else {
          this.selectedMember.role_id = this.roles[0].id;
        }
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.memberSubscription != null) {
      this.memberSubscription.unsubscribe();
    }
  }

  onSubmit(member: Member) {

    console.log('submit', member);

    //save or edit
    if (this.isNew) {
      this.memberService.save(this.selectedMember);
    } else { // edit
      this.memberService.edit(this.selectedMember, member);
    }

    // if member is changed
    this.memberSubscription = this.memberService.memberChanged.subscribe((members: Member[]) => {
      this.memberSubscription.unsubscribe();
      if (this.isNew === false) {
        this.router.navigate(['/member', this.memberIndex]);
      } else {
        this.router.navigate(['/member', this.memberService.getAll().length]);
      }
    });

  }

  search(event) {
    if (event.originalTarget.value.length > 2) {

      this.memberService.searchMember(event.originalTarget.value).subscribe(
        (data: any) => {
          this.searchResults = data;
        }
      );
    }
  }

  memberSelect(result) {
    this.searchValue = '';
    this.searchResults = [];
    //console.log(result);
    const member = new Member(result.aFirstName, result.aNamePrefix + ' ' + result.aLastName, result.aBirthDate);
    member.swim_times_id = result.aId;
    member.knzb_number = result.aRegistration;
    if (result.aGender == 1) {
      member.sex = 'male';
    } else {
      member.sex = 'female';
    }

    // if is selected member
    if (this.selectedMember != null) {
      member.id = this.selectedMember.id;
    }
    this.selectedMember = member;

    // clear result

    this.searchResults = [];
    // rewrite first name for remove the lastname for change detection for angular
    this.selectedMember.first_name = result.aFirstName;


  }

}
