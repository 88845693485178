import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localnl from '@angular/common/locales/nl';
import {ChartsModule} from 'ng2-charts';
import {AppComponent} from './app.component';
import {LoginComponent} from './auth/login/login.component';
import {routing} from './app-routing.module';
import {AuthService} from './auth/auth.service';
import {AppService} from './app.service';
import {AuthGuard} from './auth/auth.guard';
import {TrainingListComponent} from './training/training-list/training-list.component';
import {TrainingItemComponent} from './training/training-list/training-item.component';
import {TrainingService} from './training/training.service';
import {TrainingDetailComponent} from './training/training-detail/training-detail.component';
import {TrainingFormComponent} from './training/training-form/training-form.component';
import {TrainingComponent} from './training/training.component';
import {TrainingStartComponent} from './training/training-start.component';
import {MemberComponent} from './member/member.component';
import {MemberStartComponent} from './member/member-start.component';
import {MemberItemComponent} from './member/member-list/member-item.component';
import {MemberListComponent} from './member/member-list/member-list.component';
import {MemberService} from './member/member.service';
import {MemberDetailComponent} from './member/member-detail/member-detail.component';
import {MemberFormComponent} from './member/member-form/member-form.component';
import {TrainingMemberComponent} from './training/training-member/training-member.component';
import {FilterMembersPipe} from './training/training-member/filter-members.pipe';
import {BarLoaderComponent} from './shared/bar-loader/bar-loader.component';
import {PersonalRecordComponent} from './swimtimes/personal-record/personal-record.component';
import {SwimTimesService} from './swimtimes/swim-times.service';
import {PressentChartsComponent} from './member/pressent-charts/pressent-charts.component';
import {RoleService} from './roles/role.service';
import {ClubService} from './club/club.service';
import {DashboardComponent} from './dashboard/dashboard/dashboard.component';
import {WpPostFeedComponent} from './dashboard/wp-post-feed/wp-post-feed.component';
import {RecordDetailComponent} from './swimtimes/record-detail/record-detail.component';
import {OauthComponent} from './auth/oauth/oauth.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PermissionGuard} from './auth/permission.guard';
import {registerLocaleData} from '@angular/common';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {RecordService} from './service/record.service';
import {HelperService} from './service/helper.service';
import {RecordComponent} from './record/record.component';
import {SplitRecordChartComponent} from './record/split-record-chart/split-record-chart.component';
import {RecordFormComponent} from './record/record-form/record-form.component';
import {StyleService} from './service/style.service';
import {StyleFormComponent} from './style/style-form/style-form.component';
import {NgxMaskModule} from 'ngx-mask';
import {PairRequestCardComponent} from './dashboard/pair-request-card/pair-request-card.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {MessagingService} from './shared/messaging.service';
import {LayoutModule} from './layout/layout.module';
import {UserService} from './user/user.service';
import {SharedModule} from './shared/shared.module';
import {HeaderComponent} from './layout/navigation/header/header.component';
import {SidenavListComponent} from './layout/navigation/sidenav-list/sidenav-list.component';
import {ExportDialogComponent} from './training/export-dialog/export-dialog.component';
import {NewsService} from './service/news.service';
import {NewsFeedComponent} from './dashboard/news-feed/news-feed.component';
import {NewsFormDialogComponent} from './dashboard/news-feed/news-form-dialog/news-form-dialog.component';
import {NewsItemComponent} from './dashboard/news-feed/news-item/news-item.component';
import * as Sentry from '@sentry/browser';
import { Integrations } from "@sentry/tracing";
import {MarkdownModule} from 'ngx-markdown';
import {ResponseInterceptor} from './service/app.response.interceptor';
import {ValidationErrorService} from './service/validation-error.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {GroupService} from './service/group.service';
import { TrainingSignupComponent } from './training/training-signup/training-signup.component';

import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import { MemberImportComponent } from './member/member-import/member-import.component';

registerLocaleData(localnl);

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

Sentry.init({
  dsn: 'https://44ed3a04d4234313801b037681e4e57b@sentry.io/1551402',
  release: 'swimhub-angular@' + environment.version,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    if (environment.production === true) {
      const eventId = Sentry.captureException(error.originalError || error);
      // Sentry.showReportDialog({eventId});
    } else {
      console.error(error);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavListComponent,
    LoginComponent,
    TrainingListComponent,
    TrainingItemComponent,
    TrainingDetailComponent,
    TrainingFormComponent,
    TrainingComponent,
    TrainingStartComponent,
    MemberComponent,
    MemberStartComponent,
    MemberListComponent,
    MemberItemComponent,
    MemberDetailComponent,
    MemberFormComponent,
    TrainingMemberComponent,
    FilterMembersPipe,
    BarLoaderComponent,
    PersonalRecordComponent,
    PressentChartsComponent,
    DashboardComponent,
    WpPostFeedComponent,
    RecordDetailComponent,
    OauthComponent,
    RecordComponent,
    SplitRecordChartComponent,
    RecordFormComponent,
    StyleFormComponent,
    PairRequestCardComponent,
    ExportDialogComponent,
    NewsFeedComponent,
    NewsFormDialogComponent,
    NewsItemComponent,
    TrainingSignupComponent,
    MemberImportComponent

  ],
  entryComponents: [
    StyleFormComponent,
    ExportDialogComponent,
    NewsFormDialogComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    routing,
    BrowserAnimationsModule,
    ChartsModule,
    NgxMaskModule.forRoot(), // masks
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    LayoutModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MarkdownModule.forRoot(),
    NgxMaterialTimepickerModule
  ],
  providers: [
    AppService,
    AuthService,
    AuthGuard,
    PermissionGuard,
    TrainingService,
    MemberService,
    UserService,
    SwimTimesService,
    RoleService,
    ClubService,
    RecordService,
    HelperService,
    StyleService,
    MessagingService,
    NewsService,
    ValidationErrorService,
    GroupService,

    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: LOCALE_ID, useValue: 'nl'},
    { provide:
      HTTP_INTERCEPTORS, useClass:
      ResponseInterceptor, multi:
        true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
