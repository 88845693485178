import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {MemberService} from '../member.service';
import {AuthService} from '../../auth/auth.service';
import {Member} from '../../shared/member';

@Component({
  selector: 'app-pressent-charts',
  templateUrl: './pressent-charts.component.html',
  styleUrls: ['./pressent-charts.component.css']
})
export class PressentChartsComponent implements OnInit, OnChanges {
  // Doughnut chart by day
  public dayChartLabels: string[] = ['maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag', 'zondag'];
  public dayChartData: number[] = [0, 0, 0, 0, 0, 0, 0];
  public dayChartType: string = 'doughnut';

  // bar chart by month
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {

      yAxes: [{
        ticks: {
          stepSize: 1
        }
      }]
    }

  };
  public barChartLabels: string[] = ['Januari', 'Febuari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = false;

  public barChartData: any[] = [
    {data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'aanwezigheid'},
  ];

  public average = 0;
  @Input() member: Member ;
  @Input() profileUrl = false ;
  @Input() showMonthChart = true ;
  @Input() showdayChart = true ;


  ngOnChanges(changes: any) {
    //console.log(changes);
    //this.readStatistics(); //why did i add this line?
  }

  constructor(private memberService: MemberService, private authService: AuthService) {
  }

  showBoth() {
    if(this.showMonthChart && this.showdayChart) {
      return 50;
    } else {
      return 100
    }
  }

  ngOnInit() {
    this.readStatistics();
  }

  readStatistics() {
    if (this.member != null) {
      this.memberService.fetchStatistics(this.member.id).subscribe(
        (data: any) => {
          if (data.days.length > 0) {
            this.processDay(data.days);
            this.processMonth(data.month_count);
          }
          this.average = data.average;
        }
      );
    }
  }

  processDay(days: any) {
    let data = [];

    // loop though days
    for (let i = 0; i < 7; i++) {
      // get day by daynumer
      let day = days.filter(day => day.daynr == i);

      // check if day exist
      if (day.length > 0) {
        data.push(day[0].pressent);
      } else {
        data.push(0);
      }
    }

    this.dayChartData = data;

  }

  processMonth(months: any) {
    let data = [];

    // loop though month
    for (let i = 1; i <= 12; i++) {
      // get day by daynumer
      const day = months.filter(day => day.month == i);

      // check if day exist
      if (day.length > 0) {
        data.push(day[0].pressent);
      } else {
        data.push(0);
      }
    }

    this.barChartData[0].data = data;

  }

}
