import {EventEmitter, Injectable} from '@angular/core';
import {AppService} from '../app.service';
import {User} from '../shared/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable()
export class AuthService extends AppService {

  authChanged = new EventEmitter<User>();
  refreshBuzy = false;

  constructor(http: HttpClient, private router: Router) {
    super(http);

    this.setHTTPOptions();
  }

  private user: User;


  fetchProfile() {
    return this.http.get(this.API_URL + 'me', this.HTTP_OPTIONS);
  }

  refreshProfile() {
    if (this.refreshBuzy == false) {
      console.log('refresh profile');
      this.refreshBuzy = true;
      this.fetchProfile().subscribe(
        (user: User) => {
          this.refreshBuzy = false;
          this.storeUser(user);
          this.storeProfile(user);
          this.authChanged.emit(user);

          if(this.getSelectedClub() === null || this.getSelectedClub() === undefined) {
            if(user.member.length > 0) {
              this.storeSelectedClub(user.member[0].club_id);
            }
          }

        },
        error => {
          this.refreshBuzy = false;
        }
      );
    }
  }

  oauth2GetAppToken(code: string) {
    return this.http.post(this.BASE_URL + 'oauth/token',
      {
        grant_type: 'authorization_code',
        client_id: this.APP_ID,
        client_secret: this.APP_SERCRET,
        redirect_url: window.location.origin + '/auth/callback', // added for laravel 5.7 check this line
        code
      });
  }

  checkoauth2Freshnes() {
    const login_time = Number(this.getAuthObject().login_time);
    const expires_in = (this.getAuthObject().expires_in * 1000); // seconds to ms

    const expires_time = new Date(login_time + expires_in); // login time + 1 year

    const d = new Date(expires_time);
    d.setMonth(d.getMonth() - 1);
    const timeframe_to_refresh = Number(d); // login time + 1 year - 1 month


    // if timeframe_to_refresh lower than now > refresh token
    if (timeframe_to_refresh < Number(Date.now())) {
      console.log('time to refesh token');
      this.oauth2Refresh();
    } else {
      console.log('not yet time to refesh token', d.toLocaleDateString());
    }
  }

  oauth2Refresh() {

    if ('refresh_token' in this.getAuthObject()) { // check if refresh token exist

      const refresh_token = this.getAuthObject().refresh_token;
      this.http.post(this.BASE_URL + 'oauth/token',
        {
          grant_type: 'refresh_token',
          client_id: this.APP_ID,
          client_secret: this.APP_SERCRET,
          refresh_token,
        }).subscribe((data: any) => {

        data.login_time = String(Date.now());
        localStorage.setItem('_a', window.btoa(JSON.stringify(data)));

      });
    }
  }

  login(email: string, password: string) {
    return this.postWithoutHeaders('login', {email, password});
  }

  logout() {
    this.destoyProfile();
    this.destoyApiToken();
    localStorage.clear();
    return this.apiGet('logout');
  }

  /*
   // help functions
   */

  storeUser(user: User) {
    this.user = user;
    this.authChanged.emit(user);
  }

  getUser() {
    return this.user;
  }

  destroyUser() {
    this.user = null;
  }

  storeApiToken(api_token: string) {
    localStorage.setItem('api_token', api_token);
  }

  getAuthObject() {
    const authObject = localStorage.getItem('_a');

    if (authObject != null) {
      return JSON.parse(window.atob(authObject));
    }

    return null;
  }

  storeProfile(user: User) {
    localStorage.setItem('_u', window.btoa(JSON.stringify(user)));
  }

  /**
   * get profile form local storage
   * @returns {any}
   */
  getProfile() {
    if (localStorage.getItem('_u') === null) {
      return new User(0, '', '', '');
    }

    return JSON.parse(window.atob(localStorage.getItem('_u')));
  }

  destoyProfile() {
    localStorage.removeItem('_u');
  }

  storeSelectedClub(clubId) {
    localStorage.setItem('selected_club', clubId);
  }

  getSelectedClub() {
    return localStorage.getItem('selected_club');
  }


  destoyApiToken() {
    localStorage.removeItem('api_token');
  }

  isAuthenticated() {
    if (typeof (Storage) !== 'undefined') {
      // Code for localStorage/sessionStorage.

      if (localStorage.getItem('_a') !== null) {
        return true;
      }
    }
    return false;
  }

  // authorisation


  /**
   *
   * @param {Array<string>} roles
   * @returns {boolean}
   */
  hasRole(roles: Array<string>): boolean {


    const club = this.getSelectedClub(); // get club
    const profile = this.getProfile(); // get profile with permissions

    if (profile == null || profile.hasOwnProperty('permissions') == -1 || profile.permissions == undefined) { // temp fix when users have old version
      if (this.refreshBuzy === false) {
        console.log('MIssiong permission', 'reload profile');
        this.refreshProfile();
      }
      return false;
    }

    if (this.checkIfAdminRole()) {
      return true;
    }

    for (const role of roles) {

      // check
      if (club == null || profile.permissions[club] == undefined) {
        return false;
      }

      if (profile.permissions[club].roles.indexOf(role) != -1) { // if role exist in the roles of the selected club
        return true;
      }
    }

    return (roles == null);
  }

  hasPermission(permissions: Array<string>): boolean {

    const club = this.getSelectedClub(); // get club
    const profile = this.getProfile(); // get profile with permissions

    if (profile.permissions == undefined) { // temp fix when users have old version
      // console.log('Missiong permission');
      this.refreshProfile();
      return false;
    }

    if (this.checkIfAdminRole()) {
      return true;
    }

    for (const permission of permissions) {

      // check club exist an is active
      if (club == null || profile.permissions[club] == undefined) {
        return false;
      } else if (profile.permissions[club].permissions.indexOf(permission) != -1) { // if role exist in the roles of the selected club
        return true;
      }
    }

    return (permissions == null);
  }

  /**
   * check if current user has administrator role
   * @returns {boolean}
   */
  checkIfAdminRole(): boolean {
    const profile = this.getProfile(); // get profile with permissions

    if (profile.permissions == undefined) { // temp fix when users have old version
      console.log('MIssiong permission');
      this.refreshProfile();
      return false;
    }

    for (const club in profile.permissions) { // itterate clubs
      if (profile.permissions[club].roles.indexOf('Admin') != -1) { // check if
        return true;
      }
    }
    return false;
  }


}
