import {Component, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {AuthService} from '../../auth/auth.service';
import {TrainingService} from '../training.service';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.css']
})
export class ExportDialogComponent implements OnInit {

  public years = [];
  public selectYear = new Date().getFullYear();
  public selectType = ';';
  public selectGroup = 0;

  constructor(
    public dialogRef: MatDialogRef<ExportDialogComponent>,
    private authService: AuthService,
    public dialog: MatDialog,
    private trainingService: TrainingService
  ) {
  }

  ngOnInit() {

    const startYear = 2017;
    const currentYear = new Date().getFullYear();

    for (let i = startYear; i <= currentYear; i++) {
      this.years.push(i);
    }
  }

  exportTraining() {
    this.trainingService.fetchExport(
      Number(this.authService.getSelectedClub()),
      this.selectType,
      this.selectYear,
      this.selectGroup
    ).subscribe((csvData: any) => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([csvData], {type: 'text/csv'});

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      const link = document.createElement('a');
      link.href = data;
      link.download = 'training' + this.selectYear + '.csv';
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }

  closeDialog() {
    this.dialogRef.close('close!');
  }

}
