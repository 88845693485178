/**
 * Created by Patrick on 3-4-2017.
 */
import {Member} from './member';
import {User} from './user';
import {Style} from './style';

export class Record {

  public id: number;
  public created_at: string;
  public updated_at: string;

  public style: Style;
  public member: Member;
  public creator_id: number
  public creator: User;

  constructor(
    public record: string,
    public split_record: Array<string>,
    public style_id: number,
    public date: string,
    public member_id: number,
    public offical: boolean,
  ) {

  }

}
