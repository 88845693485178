<mat-card id="training-detail">
  <mat-card-header>
    <mat-card-title>
      {{selectedTraining?.date | date:'EE d MMMM' }} <span
      *ngIf="selectedTraining?.time_start != null"> {{selectedTraining?.time_start.slice(0, -3)}}
      - {{selectedTraining?.time_end.slice(0, -3)}} </span>
    </mat-card-title>
    <mat-card-subtitle>
      <p *ngIf="selectedTraining?.max_participants != null">
        Maximaal aanwezigen: {{selectedTraining?.max_participants}}
      </p>
      {{selectedTraining?.short_description}}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>{{selectedTraining?.description}}</p>

    <p class="text-danger" *ngIf="selectedTraining?.max_participants > 0 && selectedTraining?.members.length >= selectedTraining?.max_participants">
       Maximaal aantal aanmeldingen bereikt
    </p>

    <h3>Aan/afmelden</h3>
    <mat-list *ngIf="selectedTraining?.id > 0">
      <mat-list-item fxLayout="column" fxFlexFill *ngFor="let member of members">
        <span fxLayoutAlign="start center" fxFlex="95"
              style="height: 50px">
              <span style="width: 300px">{{member.first_name}} {{member.name}}
              </span>
        </span>

        <button mat-flat-button color="primary" (click)="addMember(member)" *ngIf="!inTraining(member.id)" [disabled]="selectedTraining?.members.length >= selectedTraining?.max_participants ">Aanmelden<mat-icon style="padding-top: 10px">add</mat-icon></button>
        <button mat-flat-button color="warn" (click)="removeMember(member)" *ngIf="inTraining(member.id)">Afmelden<mat-icon style="padding-top: 10px">clear</mat-icon></button>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>

  </mat-card-content>
</mat-card>


