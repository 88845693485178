<div style="min-width: 75vw;">
  <h2 mat-dialog-title>Niews artikel</h2>
  <mat-dialog-content>
    <mat-form-field class="full-width">
      <input matInput cdkFocusInitial type="text" placeholder="Titel" [(ngModel)]="news.title" required>
    </mat-form-field>


    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxFlexFill>

      <div fxFlex="50" class="sec1" fxFlex.xs="55" style="margin: 0 10px; border-right: 1px solid #efefef">
        <mat-form-field class="full-width">
          <textarea matInput placeholder="Bericht" [(ngModel)]="news.body" rows="20" required></textarea>
          <small><a href="https://guides.github.com/pdfs/markdown-cheatsheet-online.pdf">markdown</a> is
            ondersteund</small>
        </mat-form-field>
      </div>
      <div fxFlex="50" class="sec2">

        <p class="text-muted">Text voorbeeld</p>
        <markdown [data]="news.body"></markdown>
      </div>


    </div>

    <div fxLayout='column' hidden>
      <div fxFlexAlign="end">
        <mat-form-field>
          <input matInput type="email" placeholder="email voor test mail" required>
        </mat-form-field>
        <button mat-stroked-button color="accent">Verstuur test</button>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button color="primary" (click)="save()">Opslaan</button>
  </mat-dialog-actions>
</div>
