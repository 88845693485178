<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h1 class="h4">Leden importeren</h1>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <form>

      <div>
        <label id="member-csv-upload-label">CSV leden bestand</label><br />
        <input type="file" placeholder="csv bestand" aria-labelledby="member-csv-upload-label" name="file"
          accept=".csv, .txt" #fileupload [formControl]="fileFormControl">
      </div>
      <br />

      <mat-radio-group class="radio-group" aria-label="Select an option" aria-labelledby="example-radio-group-label"
        [(ngModel)]="seperatorInput" [ngModelOptions]="{standalone: true}">
        <label id="example-radio-group-label">CSV seperator</label>
        <mat-radio-button *ngFor="let seperator of seperators" [value]="seperator" name="seperatorInput" required>
          <strong>{{seperator}}</strong> gescheiden
        </mat-radio-button>
      </mat-radio-group>

      <mat-radio-group class="radio-group" aria-label="Select an option" aria-labelledby="example-radio-group-label"
        [(ngModel)]="insertTypeInput" [ngModelOptions]="{standalone: true}">
        <label id="example-radio-group-label">Manier leden invoegen</label>
        <mat-radio-button value="1" required> Nieuwe invoegen, bestaande bijwerken
        </mat-radio-button>
        <mat-radio-button value="0" required> Alleen bestaande bijwerken
        </mat-radio-button>
      </mat-radio-group>

      <button type="submit" mat-raised-button color="primary" (click)="handleFormInput(fileupload.files)">Start
        import</button>
    </form>
  </mat-card-content>
</mat-card>

<br />

<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h1 class="h4">Resultaten</h1>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-tab-group>
      <mat-tab label="Toegevoegd ({{result.created.length}}) ">
        <table mat-table [dataSource]="result.created">

          <!-- Position Column -->
          <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef> voornaam </th>
            <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Naam </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="birthdate">
            <th mat-header-cell *matHeaderCellDef> 	Geboortedatum </th>
            <td mat-cell *matCellDef="let element"> {{element.birthdate}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
      </mat-tab>
      <mat-tab label="Geupdate ({{result.updated.length}})">

        <table mat-table [dataSource]="result.updated">

          <!-- Position Column -->
          <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef> voornaam </th>
            <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Naam </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="birthdate">
            <th mat-header-cell *matHeaderCellDef> 	Geboortedatum </th>
            <td mat-cell *matCellDef="let element"> {{element.birthdate}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

      </mat-tab>
      <mat-tab label="gefaald ({{result.failed.length}})">

        <table mat-table [dataSource]="result.failed">

          <!-- Position Column -->
          <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef> voornaam </th>
            <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Naam </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="birthdate">
            <th mat-header-cell *matHeaderCellDef> 	Geboortedatum </th>
            <td mat-cell *matCellDef="let element"> {{element.birthdate}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> 	errors </th>
            <td mat-cell *matCellDef="let element"><ul [innerHtml]="errorsToList(element.errors)"></ul> </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsWithError"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsWithError;"></tr>

        </table>

      </mat-tab>
    </mat-tab-group>

  </mat-card-content>
</mat-card>
