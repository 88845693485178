import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../user/user.service';
import {User} from '../../shared/user';
import {Member} from '../../shared/member';
import {TrainingService} from '../training.service';
import {Subscription} from 'rxjs';
import {Training} from '../training';

@Component({
  selector: 'app-training-signup',
  templateUrl: './training-signup.component.html',
  styleUrls: ['./training-signup.component.css']
})
export class TrainingSignupComponent implements OnInit {

  user: User;
  members: Member[];
  selectedClub = null;
  selectedTraining: Training;
  subscription: Subscription;
  trainingIndex: number;

  constructor(public auth: AuthService,
              public router: Router,
              private userService: UserService,
              private trainingService: TrainingService,
              private route: ActivatedRoute
              ) {
  }

  ngOnInit(): void {

    this.user = this.auth.getProfile();

    this.selectedClub = this.auth.getSelectedClub();
    this.members = this.user.member.filter(member => member.club_id == this.selectedClub);

    this.subscription = this.route.params.subscribe(
      (params: any) => {
        this.trainingIndex = params['id'];
        this.selectedTraining = this.trainingService.getTraining(this.trainingIndex);
      }
    );

    this.trainingService.trainingChanged.subscribe(
      (training: Training[]) => {
        this.selectedTraining = this.trainingService.getTraining(this.trainingIndex);
      }
    );

  }

  inTraining(memberId:number):boolean {

    if(this.selectedTraining.members.filter(member => member.id == memberId).length == 1) {
      return true;
    }

    return false;
  }

  removeMember(member: Member):void {
    this.trainingService.signUpOut(this.selectedTraining,member,false);
  }

  addMember(member:Member): void {
    this.trainingService.signUpOut(this.selectedTraining,member,true);
  }

}
