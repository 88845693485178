import {Injectable, EventEmitter} from '@angular/core';
import {AppService} from "../app.service";
import {Role} from "../shared/role";
import {HttpClient} from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import {AuthService} from '../auth/auth.service';

@Injectable()
export class RoleService extends AppService {

    roles: Role[] = [];
    roleChanged = new EventEmitter<Role[]>();

    constructor(http: HttpClient, private authService: AuthService, private snackbar: MatSnackBar) {
        super(http);
    }

    fetch() {
        this.http.get(`${this.API_URL}club/${this.authService.getSelectedClub()}/role`)
            .subscribe(
                (data: Role[]) => {
                    this.roles = data;
                    this.roleChanged.emit(this.roles);
                },
                (error:any) => this.fetch()
            );
    }

    save(role: Role) {
        return this.http.post(this.API_URL+'role',role)
            .subscribe(
                (data:Role) => {
                    this.roles.push(data);
                    this.roleChanged.emit(this.roles);
                    this.snackbar.open('Lid opgeslagen');
                },
                (error: any) => {
                    this.snackbar.open("FOUT! rol niet goed opgeslagen"+error.message,null,{duration:5000});
                    console.log("error",error)}
            );
    }

    edit(oldRole:Role, role:Role) {
        return this.http.patch(this.API_URL+'role/'+oldRole.id,role).subscribe(
            (data:Role) => {
                this.roles[this.roles.indexOf(oldRole)] = data;
                this.roleChanged.emit(this.roles);
                this.snackbar.open('Lid opgeslagen');

                //console.log('patch',this.get(this.members.indexOf(oldMember)));
            },
            (error: any) => {
                this.snackbar.open("FOUT! rol niet goed opgeslagen"+error.message,null,{duration:5000});
                console.log("error",error)}
        );
    }

    delete(role:Role) {
        this.http.delete(this.API_URL+'role/' + role.id).subscribe(
            (data: any) => {
                this.roles.splice(this.roles.indexOf(role), 1);
            },
            (error: any) => {
                this.snackbar.open("FOUT! rol niet goed opgeslagen"+error.message,null,{duration:5000});
                console.log("error",error)}
        );
    }

    getAll() {
        return this.roles;
    }

    get(index:number) {
        return this.roles[index];
    }




}
