<mat-card class="mt-3">

  <mat-card-title>
    Prestaties en testen
    <button mat-stroked-button color='accent' [routerLink]="['record/add']" aria-label="record toevoegen" ><mat-icon style="padding-top:10px;">add</mat-icon>Record toevoegen</button>
  </mat-card-title>

  <mat-accordion *ngIf="records">
    <mat-expansion-panel *ngFor="let styleId of AllStylesId()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{getStyleById(styleId).distance}}M. {{getStyleById(styleId).stroke_name}}
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
          <table mat-table [dataSource]="getRecordByStyleId(styleId)"  class="w-100 mat-elevation-z8">

            <!-- Name Column -->
            <!-- Name Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Datum </th>
              <td mat-cell *matCellDef="let element"> {{element.date | date}} </td>
            </ng-container>

            <ng-container matColumnDef="record">
              <th mat-header-cell *matHeaderCellDef> Record </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.style.input_type != 'time'"> {{element.record}} </span>
                <span *ngIf="element.style.input_type == 'time'">{{ milisecondToTime( element.record ) }} </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="split_record">
              <th mat-header-cell *matHeaderCellDef> Splits </th>
              <td mat-cell *matCellDef="let element"> {{splitsToString(element)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['date','record','split_record']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['date','record','split_record'];"></tr>
          </table>
      </ng-template>

    </mat-expansion-panel>
  </mat-accordion>
</mat-card>
