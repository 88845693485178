import {Comment} from './comment';

/**
 * Created by Patrick on 17-3-2017.
 */

export class News {
  public created_at: string;
  public updated_at: string;
  public id: number;
  public club_id: number;
  public user_id: number;
  public post_category_id: number;
  public image: string;
  public status: string;
  public comments: Comment[];

  constructor(public title: string, public body: string) {
  }

}
