<h2>Slag toevoegen</h2>

<form #form="ngForm" (ngSubmit)="onSubmit(form.value)">

  <mat-form-field class="form-control">
    <input type="text" name="stroke_name" matInput placeholder="slag" [ngModel] required>
  </mat-form-field>

  <mat-form-field class="form-control">
    <input type="number" name="distance" matInput step="1" placeholder="afstand (meters)" required [ngModel]>
  </mat-form-field>

  <mat-form-field class="form-control">
    <input type="number" name="count" min="1" matInput step="1" placeholder="aantal keer" required [ngModel]>
    <mat-hint>aantal keer dat deze afstand afgelegt wordt (Dit is bijvoorbeeld voor estafete's)</mat-hint>
  </mat-form-field>

  <div class="form-control">

    <mat-label>Type invoer van record</mat-label>
    <br/>
    <mat-radio-group class="example-radio-group" name="input_type" [ngModel] [required]="true">
      <mat-radio-button class="example-radio-button" value="time">Tijd</mat-radio-button>
      <br/>
      <mat-radio-button class="example-radio-button" value="number">Nummer</mat-radio-button>
      <br/>
    </mat-radio-group>
  </div>

  <input type="hidden" name="stroke_id" [value]="1" [ngModel]>
  <input type="hidden" name="open" [value]="0" [ngModel]>

  <button type="submit" class="btn btn-block btn-success" [disabled]="!form.valid">Opslaan</button>

</form>
