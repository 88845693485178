import {Injectable} from '@angular/core';
import {isDevMode} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Router} from "@angular/router";

@Injectable()
export class AppService {


  public BASE_URL = environment.BASE_URL;
  public API_URL = environment.BASE_URL + 'api/';
  protected APP_SERCRET = environment.APP_SERCRET;
  protected APP_ID = environment.APP_ID;
  protected APP_URL = environment.APP_URL;

  protected HTTP_OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '
    })
  };


  constructor(protected http: HttpClient) {
    this.setHTTPOptions();
  }

  getApiToken() {
    if (localStorage.getItem('_a') === null) {
      return 'empty!';
    }

    const jobj = JSON.parse(window.atob(localStorage.getItem('_a')));
    return jobj.access_token;
  }

  setHTTPOptions() {
    this.HTTP_OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getApiToken()
      })
    };
  }

  apiGet(url) {

    return this.http.get(this.API_URL + url, this.HTTP_OPTIONS);
  }

  apiPost(url, data) {

    return this.http.post(this.API_URL + url, data, this.HTTP_OPTIONS);
  }

  apiPatch(url, data) {
    return this.http.patch(this.API_URL + url, data, this.HTTP_OPTIONS);
  }

  apiDelete(url) {
    return this.http.delete(this.API_URL + url, this.HTTP_OPTIONS);
  }

  postWithoutHeaders(url, data) {
    return this.http.post(this.API_URL + url, data);
  }


}
