import { Component, Input, OnInit } from '@angular/core';
import { Member } from "../shared/member";
import { RecordService } from "../service/record.service";
import { MemberService } from "../member/member.service";
import { Record } from "../shared/record";
import { StyleService } from '../service/style.service';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.css']
})
export class RecordComponent implements OnInit {

  @Input() memberIndex = null;
  @Input() member: Member;
  public records: Record[];


  constructor(public recordService: RecordService, private memberService: MemberService) {
  }

  ngOnInit() {

    if (this.memberIndex != null) {
      this.member = this.memberService.getById(this.memberIndex);
    }

    this.recordService.fetchMemberRecord(this.member);

    this.recordService.recordEmitter.subscribe((objects: Record[]) => {
      this.records = objects;
    })

  }

  getStyleById(styleId){
    return this.recordService.getAll().find(record => record.style.id == styleId).style
  }

  getRecordByStyleId(id:Number) : Record[]{
    return this.recordService.getByStyleId(id);
  }

  AllStylesId() {
    return this.recordService.getAll().map(record => record.style_id).filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
  }

  splitsToString(record:Record){
    if(record.style.input_type == 'time'){
      let output = '';
      for(let split of record.split_record ){
        output += this.milisecondToTime(split)+' , '
      }
      return output;
    }
    return record.split_record.toString();

  }

  milisecondToTime(milliseconds) {

    return this.recordService.parseMillisecondsIntoReadableTime(milliseconds);
  }
}
