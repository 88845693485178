import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HelperService} from "./helper.service";
import {Record} from "../shared/record";
import {Member} from "../shared/member";

@Injectable({
    providedIn: 'root'
})
export class RecordService implements ServiceInterface {

    constructor(private http: HttpClient, private  helper: HelperService) {

    }

    private records: Record[] = [];
    public recordEmitter = new EventEmitter<Record[]>();


    fetch() {
        this.http.get<Record[]>(this.helper.APIUrl + 'record', this.helper.httpOptions).subscribe(
            (objects: Record[]) => {
                this.records = objects;
                this.recordEmitter.emit(this.records);
            }
        );
    }

    fetchMemberRecord(member:Member) {
        this.http.get<Record[]>(this.helper.APIUrl + 'record/'+member.id, this.helper.httpOptions).subscribe(
            (objects: Record[]) => {
                this.records = objects;
                this.recordEmitter.emit(this.records);
            }
        );
    }

    getAll() {
        return this.records;
    }

    get(id: number) {
        return this.records[id];
    }

    getByStyleId(styleId:Number) {
      return this.getAll().filter(record => record.style_id === styleId);
    }

    create(object: Record) {
        this.http.post(this.helper.APIUrl + 'record', object, this.helper.httpOptions).subscribe(
            (record: Record) => {
                this.records.push(record);
                this.recordEmitter.emit(this.records);
            }
        );
    }

    delete(object: Record) {
        this.http.delete(this.helper.APIUrl + 'record'+object.id, this.helper.httpOptions).subscribe(
            (record: Record[]) => {
                this.records.splice(this.records.indexOf(object), 1);
                this.recordEmitter.emit(this.records);
            }
        );
    }

    update(object: Record) {
        this.http.patch(this.helper.APIUrl + 'record'+object.id,object, this.helper.httpOptions).subscribe(
            (record: Record[]) => {
                this.records = record;
                this.recordEmitter.emit(this.records);
            }
        );
    }

  /**
   *
   * @param milliseconds
   */
  parseMillisecondsIntoReadableTime(milliseconds){
        var ms = milliseconds.substr(milliseconds.length - 3)
        milliseconds = parseInt(milliseconds);

        //Get hours from milliseconds
        var hours = milliseconds / (1000*60*60);
        var absoluteHours = Math.floor(hours);
        var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        //Get remainder from hours and convert to minutes
        var minutes = (hours - absoluteHours) * 60;
        var absoluteMinutes = Math.floor(minutes);
        var m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;

        //Get remainder from minutes and convert to seconds
        var seconds = (minutes - absoluteMinutes) * 60;
        var absoluteSeconds = Math.floor(seconds);
        var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;


        return `${h}:${m}:${s}.${ms}`;
    }

  /**
   * return input string to miliseconds for database
   * @param timeMaskInput (HH:MM:SS:MsMsMs 00:00:00.000)
   */
  parseTimeStringToMilisecond(timeMaskInput: string) {
    const hour = parseInt(timeMaskInput.substring(0, 2), 0) * 60 * 10 * 10; // hour
    const minutes = parseInt(timeMaskInput.substring(3, 5), 0) * 60 * 10; // minuut
    const seconds = parseInt(timeMaskInput.substring(6, 8), 0) * 10; // seoonden
    const miliseconds = timeMaskInput.substring(9, 12); // millisecond

    console.log(hour + 'h' + minutes + 'm' + seconds + 's' + miliseconds, hour + minutes + seconds + miliseconds);

    return hour + minutes + seconds + miliseconds;
  }


}
