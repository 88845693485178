

<div fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 10px">
  <div fxFlexAlign="start">
    <h1>Trainingen</h1>
  </div>
  <div fxFlexAlign="end" *ngIf="auth.hasPermission(['manage_training'])">
    <button mat-stroked-button (click)="openExportDialog()" fxHide.lt-sm>Export</button>
  </div>
</div>

<div fxFlexAlign="column" fxFlexFill fxLayoutGap.gt-sm="10px">
  <div fxFlex="40" fxFlex.lt-md="" [fxHide.lt-md]="router.url != '/training'">
    <app-training-list></app-training-list>
  </div>

  <div fxFlex class="detailView" [fxHide.lt-md]="router.url == '/training'">
    <router-outlet></router-outlet>
  </div>
</div>


<button mat-fab color="accent" class="fab-button-left" [routerLink]="['new']"
        [fxHide.lt-md]="router.url != '/training'" *ngIf="auth.hasPermission(['manage_training'])">
  <mat-icon aria-label="training toevoegen">add</mat-icon>
</button>
