import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterMembers',
    pure: false
})
export class FilterMembersPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value.length === 0) {
            return value;
        }
        //workaround for
        if(args.length == 0) {
            return value;
        }

        console.log('search',args)
        let resultArray = [];
        for (let item of value) {
            if (item['name'].toLowerCase().match('^.*' + args.toLowerCase() + '.*$')) {
                resultArray.push(item);
            }
        }
        return resultArray;
    }

}
