import {Component, OnInit, EventEmitter, isDevMode, ApplicationRef} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {Router} from '@angular/router';
import {SwUpdate} from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import {interval} from 'rxjs';
import {MessagingService} from "./shared/messaging.service";
import {environment} from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public networkStatus: Boolean = true;
  networkblabla = new EventEmitter<any>();
  public version = '';


  // setup toaster view container
  constructor(private authService: AuthService, private router: Router, private updates: SwUpdate, private snackbar: MatSnackBar, private applicationRef: ApplicationRef, private messagingService: MessagingService) {

    // show update message
    updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
      const snackBarRef = this.snackbar.open(
        'Nieuwe versie van swimhub beschikbaar. Herlaad de app om te activeren',
        'herlaad',
        {duration: 10000}
      );

      snackBarRef.onAction().subscribe(() => {
        location.reload();
      });


    });
    // recheck for new verion
    if (!isDevMode()) {
      // updates.checkForUpdate(); // hope it active the service worker on desktop (https://github.com/angular/angular/issues/20970)
      // interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()); // maby i must remove this line
    }

  }

  ngOnInit() {

    this.version = environment.version;

    // Update the online status icon based on connectivity
    window.addEventListener('online', this.updateIndicator);
    window.addEventListener('offline', this.updateIndicator);

    // refresh profile by loading application
    if (this.authService.getAuthObject() !== null) {
      this.authService.refreshProfile();
      this.authService.checkoauth2Freshnes();
    }

    this
      .networkblabla
      .subscribe(
        (
          netwerkstatus: boolean
        ) => {
          this
            .networkStatus = netwerkstatus;
        }
      )
    ;


//subscribe to incomming push messages
    if (("Notification" in window)) { //check nofication support
      this.messagingService.receiveMessage()
    }

  }

  updateIndicator() {
    console.log('connection change');
    this.networkStatus = navigator.onLine;

    // this.networkblabla.emit(1);
    console.log(this.networkStatus);
    // Show a different icon based on offline/online
  }


}
