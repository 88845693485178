import {Component, OnInit} from '@angular/core';
import {TrainingService} from '../training.service';
import {Training} from '../training';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styles: [`

    mat-list-item {
      background: white;
      border-bottom: 1px solid #e0e0e0
    }

    .active {
    background: #3f51b5;
    color: #FFFFFF;
  }
   `]
})
export class TrainingListComponent implements OnInit {

  trainings: Training[] = null;
  selectedMonth: string;
  selectedYear: string;

  constructor(private trainingService: TrainingService, public router: Router, private authService: AuthService) {
  }

  ngOnInit() {

    const d = new Date();
    this.selectedMonth = (d.getMonth() + 1).toString();
    this.selectedYear = d.getFullYear().toString();

    if (this.authService.getSelectedClub() != null)
    {
      this.trainingService.fetchTrainingenOnDate(this.selectedMonth, this.selectedYear);
    }


    this.trainingService.trainingChanged.subscribe(
      (training: Training[]) => this.trainings = training
    );

  }

  SelectionChange() {
    this.trainings = null;
    this.trainingService.fetchTrainingenOnDate(this.selectedMonth, this.selectedYear);
  }

  generateArrayOfYears() {
    var max = new Date().getFullYear();
    var min = 2017;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years;
  }

}
