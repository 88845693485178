<mat-accordion>
    <mat-expansion-panel *ngFor="let record of records">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{record.style}}
            </mat-panel-title>
            <mat-panel-description>
                <strong>{{record.resulttime}}</strong> <small>({{record.course}} bad)</small>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
            <h4>Detail</h4>
            <p>
                <strong>
                    {{record.style}} - {{record.resulttime}}
                    <small>( {{record.course}} bad )</small>
                </strong>
                <br/>
                {{record.date | date}} ({{record.mCity}} - {{record.mName}}) <br/>

            </p>

            <app-record-detail [courseNr]="record.rCourse" [styleNr]="record.rStyle"
                               [swimTimesId]="swimTimesId" [memberNr]="memberIndex"></app-record-detail>
        </ng-template>

    </mat-expansion-panel>
</mat-accordion>
