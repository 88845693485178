<!-- Nav menu -->

<mat-toolbar color="primary">
  <div fxHide.gt-sm>
    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div>
    <a fxHide.gt-sm routerLink="/dashboard">Swimhub</a>
  </div>
  <div fxFlex fxLayout fxLayoutAlign="start" fxHide.lt-md>
    <ul fxLayout fxLayoutGap="15px" class="navigation-items">
      <li>
        <a routerLink="/dashboard">Swimhub</a>
      </li>
      <li  *ngIf="isAuth() && hasRole(['Admin'])">
        <a routerLink="/user"><i class="material-icons">contacts</i>Gebruikers</a>
      </li>
      <li  *ngIf="isAuth() && auth.hasPermission(['show_training'])">
        <a routerLink="/training"><i class="material-icons">pool</i>Trainingen</a>
      </li>
      <li  *ngIf="isAuth() && auth.hasPermission(['manage_member'])">
        <a routerLink="/member"><i class="material-icons">group</i>Leden</a>
      </li>
      <li  *ngIf="isAuth() && auth.hasPermission(['show_calender'])" >
        <a routerLink="/club/schedule"><i class="material-icons">calendar_today</i>Kalender</a>
      </li>
      <li  *ngIf="isAuth() && auth.hasPermission(['manage_meet']) ">
        <a routerLink="/meet"><i class="material-icons">gps_fixed</i>Wedstrijden</a>
      </li>
      <li  *ngIf="isAuth() && auth.hasPermission(['manage_group']) ">
        <a routerLink="/group"><i class="material-icons">group_work</i>Groepen</a>
      </li>
    </ul>
  </div>
  <div fxFlex  fxLayout fxLayoutAlign="end" >
    <mat-list-item [matMenuTriggerFor]="menu">
      <i class="material-icons">account_circle</i>
      <a fxHide.xs>{{profile?.first_name}} {{profile?.name}}</a>
    </mat-list-item>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/user','me']" >Profiel</button>
      <button mat-menu-item [routerLink]="['/club/select']" >Club beheer wisselen</button>
      <button  mat-menu-item style="cursor: pointer" (click)="onLogout()">Logout</button>
    </mat-menu>

  </div>
</mat-toolbar>
<!--
<nav class="navbar navbar-expand-md navbar-dark bg-primary" [ngClass]="status ? 'fixed-top' : ''">
    <button class="navbar-toggler navbar-toggler-right"  (click)="toggleMenu()" type="button"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" [routerLink]="['/dashboard']">Swimhub</a>

    <div class="navbar-collapse offcanvas-collapse" [ngClass]="status ? 'open' : ''" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item" *ngIf="isAuth()">
                <a class="nav-link"[routerLink]="['/dashboard']" (click)="toggleMenu()" ><i class="material-icons">apps</i>Dashboard</a>
            </li>
            <li class="nav-item" *ngIf="isAuth() && hasRole(['Administrator'])">
                <a class="nav-link" [routerLink]="['/user']" (click)="toggleMenu()" ><i class="material-icons">contacts</i>Gebruikers</a>
            </li>
            <li class="nav-item" *ngIf="isAuth() && auth.hasPermission(['manage_training'])">
                <a class="nav-link" [routerLink]="['/training']" (click)="toggleMenu()" ><i class="material-icons">pool</i>Trainingen</a>
            </li>
            <li class="nav-item" *ngIf="isAuth() && auth.hasPermission(['manage_member'])">
                <a class="nav-link" [routerLink]="['/member']" (click)="toggleMenu()" ><i class="material-icons">group</i>Leden</a>
            </li>
        </ul>
        <ul class="navbar-nav">
            <li class="nav-item dropdown" *ngIf="isAuth()" >
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false" href="#"  >
                    <i class="material-icons">account_circle</i>{{profile?.first_name}} {{profile?.name}}
                </a>

                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a [routerLink]="['/user','me']" class="dropdown-item" (click)="toggleMenu()" >Profiel</a>
                    <a [routerLink]="['/club/select']" class="dropdown-item" (click)="toggleMenu()" >Club beheer wisselen</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" style="cursor: pointer" (click)="onLogout()">Logout</a>
                </div>
            </li>
        </ul>
    </div>
</nav>
-->
