<div id="member-detail">
  <mat-card>

    <div class="float-right" *ngIf="auth.hasPermission(['manage_member'])">
      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="onDelete(selectedMember)">Verwijder lid</button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxFlexFill fxLayout.lt-md="column" fxLayoutGap="40px">
      <div fxFlex="25" fxLayout="column" fxFlex.lt-md="100%">
        <div style="margin-bottom: 50px;">
          <img src="/assets/swimmer.svg" id="member-profile-img" class="rounded-circle" height="300">
        </div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center" fxFlexFill>
          <a href="tel:{{selectedMember?.telephone}}">
            <mat-icon class="contact-icon">call</mat-icon>
          </a>
          <a href="mailto:{{selectedMember?.email}}">
            <mat-icon class="contact-icon">email</mat-icon>
          </a>

        </div>

      </div>
      <div fxFlex="50">
        <h1> {{selectedMember?.first_name}} {{selectedMember?.name}} </h1>
        <h3>{{selectedMember?.role[0]?.name}}</h3>
        <table id="member-info">
          <tr>
            <th>Geslacht</th>
            <td i18n="gender">{selectedMember?.sex, select, male {male} female {female} other {other} }</td>
          </tr>
          <tr>
            <th>Geboortedatum</th>
            <td>{{selectedMember?.birthdate |date}}</td>
          </tr>
          <tr>
            <th>KNZB nummer</th>
            <td>{{selectedMember?.knzb_number}}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{{selectedMember?.status.name}}</td>
          </tr>
          <tr *ngIf="selectedMember?.reference != null">
            <th>referensie<br /></th>
            <td>{{selectedMember?.reference}}</td>
          </tr>
          <tr *ngIf="selectedMember?.custom1 != null">
            <th>extra veld</th>
            <td>{{selectedMember?.custom1}}</td>
          </tr>
          <tr *ngIf="selectedMember?.comments != null && selectedMember?.comments.length > 1">
            <th>aantekening</th>
            <td>{{selectedMember?.comments}}</td>
          </tr>
          <tr
            *ngIf="selectedMember?.internal_comment != null && selectedMember?.internal_comment.length > 1 && this.auth.hasPermission(['manage_member']) ">
            <th>Interne aantekening<br /> <small>(alleen zichtbaar voor trainers)</small></th>
            <td class="table-warning">{{selectedMember?.internal_comment}}</td>
          </tr>
        </table>
      </div>
    </div>
  </mat-card>

  <div *ngIf="selectedMember != null">
    <app-pressent-charts [member]="selectedMember"></app-pressent-charts>
  </div>

  <h2>Persoonlijk records</h2>
  <p class="text-danger" *ngIf="selectedMember?.swim_times_id == null">
    Geen swimtimes koppeling. Kan geen records ophalen
  </p>
  <app-swimtimes-record [memberIndex]="memberIndex" [swimTimesId]="selectedMember?.swim_times_id">
  </app-swimtimes-record>


  <app-record *ngIf="selectedMember" [memberIndex]="memberIndex"></app-record>

  <button mat-fab color="accent" class="fab-button-left" [routerLink]="['/member',memberIndex,'edit']"
    *ngIf="auth.hasPermission(['manage_member'])">
    <mat-icon aria-label="bewerken">edit</mat-icon>
  </button>
</div>
