import {EventEmitter, Injectable} from '@angular/core';
import {AppService} from '../app.service';
import {Member} from '../shared/member';
import {AuthService} from '../auth/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class MemberService extends AppService {

  members: Member[] = [];
  pairRequest: Member[] = null;
  memberChanged = new EventEmitter<Member[]>();
  memberPairChanged = new EventEmitter<Member[]>();

  constructor(http: HttpClient, private authService: AuthService, public snackbar: MatSnackBar) {
    super(http);
  }

  fetchMembers() {
    this.http.get<Member[]>(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/member', this.HTTP_OPTIONS)
      .subscribe(
        (members: Member[]) => {
          this.members = members;
          this.memberChanged.emit(this.members);
        }
      );
  }

  fetchFirstContact() {
    this.http.get<Member[]>(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/member/select', this.HTTP_OPTIONS)
      .subscribe(
        (members: Member[]) => {
          this.members = members;
          this.memberChanged.emit(this.members);
        }
      );
  }


  fetchPairRequest() {
    this.http.get<Member[]>(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/member/pair', this.HTTP_OPTIONS)
      .subscribe(
        (members: Member[]) => {
          this.pairRequest = members;
          this.memberPairChanged.emit(this.pairRequest);
        }
      );
  }

  fetchMember(id: number) {
    this.http.get<Member>(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/member/' + id, this.HTTP_OPTIONS)
      .subscribe(
        (member: Member) => {
          this.members.push(member);
          this.memberChanged.emit(this.members);
        }
      );
  }

  searchMember(name) {
    return this.http.get(this.API_URL + 'swimtimes/search/' + name, this.HTTP_OPTIONS);
  }

  save(member: Member) {
    this.http.post(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/member', member, this.HTTP_OPTIONS)
      .subscribe(
        (data: Member) => {
          this.members.push(data);
          this.memberChanged.emit(this.members);
          this.snackbar.open('Lid opgeslagen');

        },
        (error: any) => {
          this.snackbar.open('FOUT! lid niet goed opgeslagen', null, {duration: 10000});
          console.log('error', error);
        }
      );
  }

  edit(oldMember: Member, member: Member) {
    this.http.patch<Member>(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/member/' + oldMember.id, member, this.HTTP_OPTIONS).subscribe(
      (data: Member) => {
        this.members[this.members.indexOf(oldMember)] = data;
        this.memberChanged.emit(this.members);
        this.snackbar.open('Lid bijgewerkt');

        // console.log('patch',this.get(this.members.indexOf(oldMember)));
      },
      (error: any) => {
        this.snackbar.open('FOUT! lid niet goed opgeslagen', null, {duration: 10000});
        console.log('error', error);
      }
    );
  }

  delete(member: Member) {
    this.http.delete(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/member/' + member.id, this.HTTP_OPTIONS).subscribe(
      (data: any) => {
        this.members.splice(this.members.indexOf(member), 1);
        this.snackbar.open('Lid verwijderd');
      },
      (error: any) => {
        this.snackbar.open('FOUT! lid niet goed opgeslagen', null, {duration: 10000});
        console.log('error', error);
      }
    );
  }

  import(file: any, seperator = ',', insert = '0') {

    console.log('upload stuff')

    const formData: FormData = new FormData();
    formData.append('csv', file,file.name);
    formData.append('serperator', seperator);
    formData.append('insert', insert);

    return this.http.post(`${this.API_URL}club/${this.authService.getSelectedClub()}/member/import`, formData);

  }


  getAll() {
    return this.members;
  }

  get(index: number) {
    return this.members[index];
  }

  getById(id: number) {
    const member = this.getAll().filter(member => member.id == id)[0];
    return member;
  }

  fetchStatistics(userId: number) {
    return this.http.get(this.API_URL + 'member/statistic/' + userId, this.HTTP_OPTIONS);
  }

  fetchExport(club_id: number, seperator = ';') {
    return this.http.get(this.API_URL + 'club/' + club_id + '/export/member?delimiter=' + seperator, {
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type': 'text',
        'Authorization': 'Bearer ' + this.getApiToken()
      })
    });
  }

}
