import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "./auth.service";
import {Observable} from 'rxjs';


@Injectable()
export class PermissionGuard implements CanActivate {

    private profile = null;
    private club = null;

    constructor(private authService: AuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (this.authService.checkIfAdminRole()) {
            return true;
        }

        //{path: 'user', canActivate:[AuthGuard,PermissionGuard], data : {roles:['Lid']}, component: UserComponent}
        if (next.data["roles"]) { //check if roles exist in data
            let roles = next.data["roles"] as Array<string>;
            return this.authService.hasRole(roles);
        }

        if (next.data["permission"]) { //check if permission exist in data
            let permissions = next.data["permissions"] as Array<string>;
            return this.authService.hasPermission(permissions);
        }



        return true;
    }



}
