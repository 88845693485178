import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ValidationErrorService} from "./validation-error.service";

@Injectable({
  providedIn: 'root'
})
export class ResponseInterceptor implements HttpInterceptor {

  private exceptions = [
    'api\/club\/.\\/file$',
    'api\/club\/.\\/file\/[0-9]',
    'api\/club\/.\/export/training',
    'api\/club\/.\/export/member',
    'oauth\/token',
    'api\/club\/.\/member/import',
  ];

  constructor(private authService: AuthService, private snackBar: MatSnackBar, private validationErrorService: ValidationErrorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    let bearerToken = '';
    if (localStorage.getItem('_a') !== null) {
      bearerToken = JSON.parse(window.atob(localStorage.getItem('_a'))).access_token;
    }

    // Get Auth Token from Service which we want to pass thr service call
    const authToken: any = `Bearer ${bearerToken}`;
    // Clone the service request and alter original headers with auth token.
    let authReq = req.clone({
      headers: req.headers.set('Authorization', authToken).set('Content-Type', 'application/json')
    });

    // change (expected) content type to applicaton octet-stream
    for (const url of this.exceptions) {
      if (req.url.match(url)) {
        // change request header for download and upload
        authReq = req.clone({
          headers: req.headers.set('Authorization', authToken).delete('Content-Type')
        });
        console.log('match!', 'change content-type');
      }
    }
    // Send cloned request with header to the next handler.
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {

        switch (error.status) {
          case 0: // unknown
            this.snackBar.open('Server onbereikbaar');
            console.log('http 0', 'server onbereikbaar. controleer je internet verbinding');
            break;
          case 401: // Unauthorized
            this.snackBar.open('Error 401 - login en logout om toegang te krijgen');
            console.log('http 401');
            break;
          case 403: // forbidden
            this.snackBar.open('Error 403 - Oeps. je hebt de rechten hier niet voor');
            console.log('http 403');
            break;
          case 404: // forbidden
            this.snackBar.open('Error 404 - aanvraag resource niet gevonden');
            console.log('http 403');
            break;
          case 422: // validation errors
            this.validationErrorService.addValdationMessages(error.error.errors);
            this.snackBar.open('Error 422 - Validatie fout. Controleer je ingevulde gegevens');
            break;
          case 429: // too many requests
            throw new Error("http error to many requests ")
            this.snackBar.open('Error 429 - Te veel aanvragen probeer het later nog eens');
            break;
          case 500: // server error
            this.snackBar.open('Error 500 - Oeps. Er is wat fout gegaan bij ons');
            break;
          default :
            throw new Error("http error ")
            this.snackBar.open(`foutmelding ${error.message}`);
            console.log('error', error);
        }
        return throwError(error);
      })
    );
  }
}
