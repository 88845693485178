import {Role} from './role';
import {User} from './user';
import {Group} from './Group';

/**
 * Created by Patrick on 12-3-2017.
 */

export class Member {
  public id: number;
  public knzb_number: number;
  public telephone: string;
  public sex: string;
  public picture: string;
  public email: string;
  public comments: string;
  public internal_comment: string;
  public swim_times_id: number;
  public role_id: number;
  public club_id: number;
  public role: Role[];
  public group: Group[];
  public reference:string;
  public custom1:string;
  public created_at: string;
  public updated_at: string;
  public status = {
    id: 0,
    name: 'loading',
    club_id: 0
  };
  public pivot = {
    user_id: 0,
    member_id: 0,
    trainer: 0,
    authorised: null
  };
  public user: User[];

  public selected = false;

  constructor(public first_name: string, public name: string, public birthdate: string) {
  }

  //@TODO temp for corona virus
  public age: number;
}
