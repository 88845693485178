import {Component, Input, OnInit} from '@angular/core';
import {News} from '../../../shared/news';
import {NewsFormDialogComponent} from '../news-form-dialog/news-form-dialog.component';
import {AuthService} from '../../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import {NewsService} from '../../../service/news.service';
import {Comment} from '../../../shared/comment';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.css']
})
export class NewsItemComponent implements OnInit {

  @Input() article: News;
  public showComments = false;
  public comment = new Comment('');
  public newsMenuVisable = false;
  public userId = 0;

  constructor(private newsService: NewsService, private authService: AuthService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.userId = this.authService.getUser().id;
    this.newsMenuVisable = this.authService.hasPermission(['manage_news']);
  }

  openForm(news: News = null) {
    const dialogRef = this.dialog.open(NewsFormDialogComponent, {
      data: news,
    });
  }

  edit(news: News) {
    this.openForm(news);
  }

  delete(news: News) {
    this.newsService.delete(news);
  }

  openComments() {
    this.showComments = !this.showComments;
  }

  saveComment() {
    this.newsService.createComment(this.article, this.comment);
  }

  deleteComment(comment: Comment) {
    this.newsService.deleteComment(this.article, comment);
  }

  showMenu(comment, userId) {
    if (comment.user_id === userId || this.authService.hasPermission(['manage_comment'])) {
      return true;
    }

    return false;
  }
}
