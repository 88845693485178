import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpHeaders} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

    public ServerUrl = environment.BASE_URL;
    public APIUrl: string = environment.API_URL;

    // oauth2 client
    public client_id = environment.APP_ID;
    public client_secret = environment.APP_SERCRET;

    private _access_token = '';

    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'X-Requested-With' : 'XMLHttpRequest',
            Authorization : 'Bearer ' + this._access_token
        })
    };

    constructor(protected authService: AuthService) {
        this.setupHttpHeader();
    }

    hasAccessToken() {
        this._access_token = localStorage.getItem('_a');

        if (this._access_token == null) {
            return false;
        } else {
            return true;
        }
    }

    setupHttpHeader() {

        if (this.hasAccessToken()) {
            const authObj = this.authService.getAuthObject();
            this._access_token = authObj.access_token;
        } else {
            this._access_token = '';
        }

        this.httpOptions ={
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'X-Requested-With' : 'XMLHttpRequest',
                Authorization : 'Bearer ' + this._access_token
            })
        };
    }
}
