import {Member} from "../shared/member";
import {File} from "../shared/file";
/**
 * Created by Patrick on 9-3-2017.
 */

export class Training {
    public created_at?: string;
    public updated_at?: string;
    public id?: number = 0;
    public trainer: Member[];
    public files: File[];
    public time_start: string;
    public time_end: string;
    public max_participants: number;

    constructor(public date: string, public short_description, public description: string, public members: Member[]) {}
}
