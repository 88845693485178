<mat-card id="training-detail">
  <button class="float-right" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
          *ngIf="auth.hasPermission(['manage_training'])">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-card-header>
    <mat-card-title>
      {{selectedTraining?.date | date:'EE d MMMM' }} <span
      *ngIf="selectedTraining?.time_start != null"> {{selectedTraining?.time_start.slice(0, -3)}}
      - {{selectedTraining?.time_end.slice(0, -3)}} </span>
    </mat-card-title>
    <mat-card-subtitle>
      <p *ngIf="selectedTraining?.max_participants != null">
        Maximaal aanwezigen: {{selectedTraining?.max_participants}}
      </p>
      {{selectedTraining?.short_description}}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>{{selectedTraining?.description}}</p>

    <p class="text-danger" *ngIf="selectedTraining?.max_participants > 0 && selectedTraining?.members.length >= selectedTraining?.max_participants">
      <strong>Maximaal aantal aanmeldingen bereikt. Aanmelden voor deze training is niet meer mogelijk</strong>
    </p>

    <mat-card class="bottom-buffer">
      <mat-card-header>
        <mat-card-title>
          <mat-chip-list aria-label="header">
            <h4>Bestanden</h4>
            <mat-chip color="primary" selected>{{selectedTraining?.files.length}}</mat-chip>
          </mat-chip-list>
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-list>
          <mat-list-item fxLayout="column" fxFlexFill *ngFor="let file of selectedTraining?.files">
            <a href="#" (click)="downloadFile($event,file)" fxLayoutAlign="start center" fxFlex="95"
               style="height: 50px"> {{file.name}} </a>
            <i (click)="deleteFile(file)" class="material-icons text-danger cursor-pointer">delete_forever</i>
            <mat-divider></mat-divider>
          </mat-list-item>
          <mat-list-item style="padding-top: 3px" fxLayout="row" *ngIf="auth.hasPermission(['manage_training'])">
            <input type="file" placeholder="pdf,excel,word bestand"
                   accept="application/pdf, application/vnd.ms-excel, application/msword, .xlsx, .xls, .csv, .txt, .doc, .docx"
                   #fileupload fxFlex="80" fxFlexFill>

            <button mat-button mat-stroked-button color="primary" (click)="handleFileInput(fileupload.files)"
                    fxFlex="20">
              Upload
            </button>
          </mat-list-item>
        </mat-list>


      </mat-card-content>
    </mat-card>

    <mat-card class="bottom-buffer">
      <mat-card-header>
        <mat-card-title>
          <mat-chip-list aria-label="header">
            <h4>Trainers</h4>
            <mat-chip color="primary" selected>{{trainers?.length}}</mat-chip>
          </mat-chip-list>
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-list>
          <mat-list-item fxLayout="column" fxFlexFill *ngFor="let member of trainers">
        <span fxLayoutAlign="start center" fxFlex="95"
              style="height: 50px">
              <span style="width: 200px">{{member.first_name}} {{member.name}}</span>
              <span *ngIf="auth.hasPermission(['manage_training'])">{{member.age}}</span>
        </span>
            <i (click)="removeMember(member)" *ngIf="auth.hasPermission(['manage_training'])"
               class="material-icons text-danger cursor-pointer">delete_forever</i>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>

    <mat-card >
      <div class="float-right">
        <button mat-stroked-button color="warn" aria-label="bewerk aanwezigen"
                [routerLink]="['/training',trainingIndex,'member']"
                *ngIf="auth.hasPermission(['manage_training'])">
          toevoegen
          <mat-icon style="padding-top: 10px">add</mat-icon>
        </button>
      </div>
      <mat-card-header>
        <mat-card-title>
          <mat-chip-list aria-label="header">
            <h4>Deelnemers</h4>
            <mat-chip color="accent" selected>{{members?.length}}</mat-chip>
          </mat-chip-list>
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>

        <button mat-raised-button color="warn" aria-label="Aan/af-melden voor training"
                [routerLink]="['/training',trainingIndex,'signup']"
                *ngIf="selectedTraining?.max_participants != null">
          Aan/af-melden voor training
          <mat-icon style="padding-top: 10px">assignment_turned_in</mat-icon>
        </button>

        <mat-list *ngIf="auth.hasPermission(['manage_training'])">
          <mat-list-item fxLayout="column" fxFlexFill *ngFor="let member of members">
        <span fxLayoutAlign="start center" fxFlex="95"
              style="height: 50px">
              <span style="width: 300px">{{member.first_name}} {{member.name}}
                <mat-icon [routerLink]="['/member',member.id]" *ngIf="member.internal_comment == 1" color="warn" class="mat-icon" style="margin-top: 20px">info</mat-icon>
              </span>
              <span>{{member.age}}</span>
        </span>

            <i (click)="removeMember(member)" class="material-icons text-danger cursor-pointer">delete_forever</i>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>

<mat-menu #menu="matMenu">
  <button mat-menu-item [routerLink]="['/training',trainingIndex,'edit']">
    <mat-icon style="padding-top: 10px">edit</mat-icon>
    <span>Bewerk</span>
  </button>
  <button mat-menu-item (click)="onDelete(selectedTraining)">
    <mat-icon style="padding-top: 10px">delete_forever</mat-icon>
    <span>Verwijder</span>
  </button>
</mat-menu>
