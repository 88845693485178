<div class="col-sm-12" *ngIf="lineChartData.length > 0">
    <h3 class="h4">Geschiedenis</h3>
    <div style="display: block">
        <canvas baseChart height="400"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [legend]="lineChartLegend"
                [chartType]="lineChartType">
        </canvas>
    </div>
</div>