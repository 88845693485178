import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {User} from '../../shared/user';
import {UserService} from '../../user/user.service';

import {Member} from '../../shared/member';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

    user: User;
    PWAPrompt; // object for requesting to install PWA
    selectedClub = null;


    constructor(public authService: AuthService, private userService: UserService, private router: Router, private snackbar: MatSnackBar) {
    }

    ngOnInit() {

        this.user = this.authService.getProfile();

        this.selectedClub = this.authService.getSelectedClub();

        this.welkomRedirect();

        // update profile
        this.authService.authChanged.subscribe(
            (user: User) => {
                if (this.user != user) {
                    console.log('auth user change');
                    this.user = user;
                    this.welkomRedirect();
                }
            }
        );



      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.PWAPrompt = e;
      });
    }

    renewProfile() {
        this.authService.refreshProfile();
    }

    welkomRedirect() {
        if (this.user != null && this.user.member.length == 0) {
            this.router.navigate(['/user/first-contact']);
        }
    }

    deleteMember(member: Member) {
        this.userService.deleteMember(member).subscribe(
            (data: any) => {
                this.snackbar.open('Lid ontkoppeld');
                this.authService.refreshProfile();
            }
        );
    }

    install() {
      // Show the prompt
      this.PWAPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.PWAPrompt.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
            this.snackbar.open('App wordt geinstaleerd');
          } else {
            console.log('User dismissed the A2HS prompt');
            this.snackbar.open('App wordt niet geinstaleerd');
          }
          this.PWAPrompt = null;
        });

    }

}
