import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HelperService} from './helper.service';
import {AuthService} from '../auth/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Group} from '../shared/Group';
import {News} from '../shared/news';
import {Member} from '../shared/member';

@Injectable({
  providedIn: 'root'
})
export class GroupService implements ServiceInterface {

  public groups: Group[] = [];
  public modelEmitter = new EventEmitter<Group[]>();

  constructor(
    private http: HttpClient,
    private  helper: HelperService,
    private  authService: AuthService,
    public snackbar: MatSnackBar
  ) {
  }

  create(object: any) {
    return this.http.post<Group>(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/group`, object);
  }

  delete(object: Group) {
    this.http.delete(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/group/${object.id}`,
      this.helper.httpOptions).subscribe(
      (objects: News) => {
        this.snackbar.open('Bericht verwijderd');
        this.groups.splice(this.groups.indexOf(object), 1);
        this.modelEmitter.emit(this.groups);
      }
    );
  }

  fetch() {
    if (this.authService.getSelectedClub() !== null) {
      this.http.get<Group[]>(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/group`).subscribe(
        (objects: Group[]) => {
          this.groups = objects;
          this.modelEmitter.emit(this.groups);
        },
        (error: any) => {
          console.log('group not loaded');
        }
      );
    }
  }

  get(id: number) {
    if (this.groups.length > 0) {
      return this.getAll().filter(group => group.id == id)[0];
    }

    const newGroup = new Group();
    newGroup.name = '';
    newGroup.description = '';
    newGroup.color = '';

    return newGroup;
  }

  getAll() {
    return this.groups;
  }

  update(object: Group) {
    return this.http.patch<Group>(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/group/${object.id}`, object);
  }

  addMember(group: Group, members: Member[]) {
    this.http.put<Group>(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/group/${group.id}/member`, members).subscribe(
      (object: Group) => {
        this.snackbar.open('lid(en) opgeslagen');
        this.groups[this.groups.indexOf(group)] = object;
        this.modelEmitter.emit(this.groups);
      }
    );
  }

  deleteMember(group: Group, member: Member) {
    this.http.delete(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/group/${group.id}/member/${member.id}`).subscribe(
      (object: Group) => {
        this.snackbar.open('lid verwijderd opgeslagen');
        this.groups[this.groups.indexOf(group)] = object;
        this.modelEmitter.emit(this.groups);
      }
    );
  }
}
