import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import {ExportDialogComponent} from './export-dialog/export-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-training',
    templateUrl: './training.component.html',
    styles: [`@media (max-width: 767.98px) { .hide-sm { display:none} }
    .fab-button-left {
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 999;
      padding-top: 3px;
    }  `]
})
export class TrainingComponent implements OnInit {

    constructor(public auth: AuthService, public router: Router, public dialog: MatDialog) {
    }

    ngOnInit() {

        if(this.auth.getSelectedClub() == null || this.auth.getSelectedClub() == '0' || this.auth.getSelectedClub() == 'undefined') {
            this.router.navigate(['/club/select']);
        }

    }

  hasRole(roles: Array<string>) {
    return this.auth.hasRole(roles);
  }

  openExportDialog() {
    this.dialog.open(ExportDialogComponent, );
  }

}
