import {EventEmitter, Injectable} from '@angular/core';
import {HelperService} from './helper.service';
import {HttpClient} from '@angular/common/http';
import {News} from '../shared/news';
import {AuthService} from '../auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Comment} from '../shared/comment';
import  './serviceInterface'

@Injectable({
  providedIn: 'root'
})
export class NewsService implements ServiceInterface {

  public news: News[];
  public modelEmitter = new EventEmitter<News[]>();

  constructor(private http: HttpClient, private  helper: HelperService, private  authService: AuthService, private snackbar: MatSnackBar) {
  }

  create(object: News) {
    this.http.post<News>(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/news`, object,
      this.helper.httpOptions).subscribe(
      (objects: News) => {
        this.snackbar.open('Bericht opgeslagen');
        this.news.push(objects);
        this.modelEmitter.emit(this.news);
      }
    );
  }

  delete(object: News) {
    this.http.delete(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/news/${object.id}`,
      this.helper.httpOptions).subscribe(
      (objects: News) => {
        this.snackbar.open('Bericht verwijderd');
        this.news.splice(this.news.indexOf(object), 1);
        this.modelEmitter.emit(this.news);
      }
    );
  }

  fetch() {
    if (this.authService.getSelectedClub() !== null) {
      this.http.get<News[]>(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/news`).subscribe(
        (objects: News[]) => {
          this.news = objects;
          this.modelEmitter.emit(this.news);
        },
        (error: any) => {
          console.log('news messages not loaded');
        }
      );
    }
  }

  get(id: number) {
    return this.news[id];
  }

  getAll() {
    return this.news;
  }

  update(object: any) {
    this.http.patch<News>(
      `${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/news/${object.id}`, object,
      this.helper.httpOptions).subscribe(
      (article: News) => {
        this.news[this.news.indexOf(object)] = article;
        this.modelEmitter.emit(this.news);
      }
    );
  }

  createComment(article: News, comment: Comment) {
    this.http.post<Comment>(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/news/${article.id}/comment`, comment,
      this.helper.httpOptions).subscribe(
      (object: Comment) => {
        this.snackbar.open('reactie opgeslagen');
        article.comments.push(object);
      }
    );
  }

  updateComment(article: News, comment: Comment) {
    this.http.post<Comment>(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/news/${article.id}/comment/${comment.id}`,
      comment,
      this.helper.httpOptions).subscribe(
      (object: Comment) => {
        this.snackbar.open('reactie opgeslagen');
        article.comments[article.comments.indexOf(comment)] = object;
      }
    );
  }

  deleteComment(article: News, comment: Comment) {
    this.http.delete(`${this.helper.APIUrl}club/${this.authService.getSelectedClub()}/news/${article.id}/comment/${comment.id}`,
      this.helper.httpOptions).subscribe(
      (object: Comment) => {
        this.snackbar.open('reactie opgeslagen');
        article.comments.splice(article.comments.indexOf(comment), 1);
        this.modelEmitter.emit(this.news);
      }
    );
  }
}
