import {Component, OnDestroy, OnInit} from '@angular/core';
import {Training} from '../training';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {TrainingService} from '../training.service';
import {MemberService} from '../../member/member.service';
import {Member} from '../../shared/member';
import {GroupService} from '../../service/group.service';
import {Group} from '../../shared/Group';

@Component({
  selector: 'app-training-member',
  templateUrl: './training-member.component.html',
  styleUrls: ['./training-member.component.css']
})
export class TrainingMemberComponent implements OnInit, OnDestroy {

  selectedTraining: Training = null;
  subscription: Subscription;
  groupSubscription: Subscription;
  trainingIndex: number;
  members: Member[] = [];
  checkboxes = [];
  groups: Group[];


  constructor(private route: ActivatedRoute,
              private trainingService: TrainingService,
              private memberService: MemberService,
              private groupService: GroupService,
              private router: Router) {
  }

  ngOnInit() {
    // prepeare members array;
    this.members = this.memberService.getAll();
    this.groups = this.groupService.getAll();

    this.groupService.fetch();

    // fetch and prepare members if membersarry in the membercontroller is emtpy
    if (this.members.length == 0) {
      this.memberService.fetchMembers();
      this.memberService.memberChanged.subscribe(
        (member: Member[]) => {
          this.members = member;
          this.prepaireCheckboxes();
        }
      );
    }

    this.groupSubscription = this.groupService.modelEmitter.subscribe(
      (groups: Group[]) => {
        this.groups = groups;
      }
    );

    this.subscription = this.route.params.subscribe(
      (params: any) => {
        this.trainingIndex = params.id;
        this.selectedTraining = this.trainingService.getTraining(this.trainingIndex);
        if (this.selectedTraining != null) {
          this.prepaireCheckboxes();
        }
      }
    );

    if (this.selectedTraining == null) { // if trainings are not loaded in service subscripe for changes
      this.trainingService.trainingChanged.subscribe(
        (training: Training[]) => {
          this.selectedTraining = this.trainingService.getTraining(this.trainingIndex);
          this.prepaireCheckboxes();
        }
      );
    }
  }

  prepaireCheckboxes() {
    if (this.members.length > 0) {
      this.checkboxes = [];
      for (const member of this.members) {
        this.checkboxes.push({
          name: member.first_name + ' ' + member.name,
          value: member.id,
          member,
          checked: this.isInTraining(member)
        });
      }
    }
  }

  isInTraining(member: Member) {
    if (this.selectedTraining !== null && this.selectedTraining.hasOwnProperty('members')) {
      for (const memberCheck of this.selectedTraining.members) {
        if (member.id == memberCheck.id) {
          return true;
        }
      }
    }

    return false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmit() {
    this.selectedTraining.members = [];

    // get the checked checkboxes and push the members to the training
    for (const checkbox of this.checkboxes.filter(opt => opt.checked)) {
      this.selectedTraining.members.push(checkbox.member);
    }
    // send training to update
    this.trainingService.members(this.selectedTraining);

    this.router.navigate(['/training', this.trainingIndex]);
  }

  filterGroup(groupId) {
    console.log(groupId);

    if (groupId == 0) {
      this.members = this.memberService.getAll();
    } else {
      const filterdMembers = [];

      for (const member of this.memberService.getAll()) {
        if (member.group.length > 0) {
          for (const group of member.group) {
            if (group.id === groupId) {
              filterdMembers.push(member);
            }
          }
        }
      }

      this.members = filterdMembers;
    }
    // recreate new checkbox array
    this.prepaireCheckboxes();
  }


}
