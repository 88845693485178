<mat-form-field>
  <mat-select [(value)]="selectedMonth" (selectionChange)="SelectionChange()" placeholder="Maand">
    <mat-option value="1">Januari</mat-option>
    <mat-option value="2">Februari</mat-option>
    <mat-option value="3">Maart</mat-option>
    <mat-option value="4">April</mat-option>
    <mat-option value="5">Mei</mat-option>
    <mat-option value="6">Juni</mat-option>
    <mat-option value="7">Juli</mat-option>
    <mat-option value="8">Augustus</mat-option>
    <mat-option value="9">September</mat-option>
    <mat-option value="10">Oktober</mat-option>
    <mat-option value="11">November</mat-option>
    <mat-option value="12">December</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field>
  <mat-select [(value)]="selectedYear" (selectionChange)="SelectionChange()" placeholder="Jaar" >
    <mat-option *ngFor="let year of generateArrayOfYears()" [value]="year">{{year}}</mat-option>

  </mat-select>
</mat-form-field>



<mat-list>
  <mat-list-item routerLinkActive="active" *ngFor="let training of trainings;let i = index" [routerLink]="[i]">
    <strong mat-line>
      {{training.date | date:'EE d MMMM' }} <small *ngIf="training?.time_start != null">{{training?.time_start.slice(0,-3)}} - {{training?.time_end.slice(0,-3)}}</small>
      <span class="badge badge-secondary float-right">{{training?.members?.length}}</span>
    </strong>
    <small mat-line>
      {{training?.short_description }}
    </small>
  </mat-list-item>
</mat-list>


<div *ngIf="trainings === null">
    <app-druploader></app-druploader>
</div>

<div *ngIf="trainings !== null && trainings.length == 0">
  Geen trainingen gevonden :(
</div>



