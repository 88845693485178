import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SwimTimesService} from '../swim-times.service';
import {Subscription} from 'rxjs';
import {MemberService} from '../../member/member.service';
import {BaseChartDirective} from 'ng2-charts';

@Component({
    selector: 'app-record-detail',
    templateUrl: './record-detail.component.html',
    styleUrls: ['./record-detail.component.css']
})
export class RecordDetailComponent implements OnInit,OnDestroy {
    @ViewChild(BaseChartDirective, {static: false}) chart: BaseChartDirective;

    subscription: Subscription;
    @Input() courseNr: number = 0;
    @Input() styleNr: number = 0;
    @Input() memberNr: number = 0;
    @Input() swimTimesId: number = 0;
    data: any = [];

    constructor(private route: ActivatedRoute,
                private swimTimesService: SwimTimesService,
                private memberService: MemberService,
                private router: Router) {
    }

    ngOnInit() {

        if (this.courseNr == 0) {

            this.subscription = this.route.params.subscribe(
                (params: any) => {
                    this.courseNr = params['course'];
                    this.styleNr = params['style'];
                    this.memberNr = params['id'];

                    this.loadData();
                }
            );
        } else {
            console.log("datadump", this.courseNr+ " "+ this.styleNr+ " "+ this.memberNr);
            this.loadData();
        }
    }

    ngOnDestroy() {
        //this.subscription.unsubscribe();
    }

    loadData() {

        console.log(this.memberService.get(this.memberNr),'test'); //<-- wrong member!!! why!

        if(this.memberService.get(this.memberNr) != null) {
            this.swimTimesService.getStyleStastic(this.swimTimesId, this.styleNr, this.courseNr).subscribe(
                (data: any) => {
                    this.data = data;
                    console.log('start process');
                    this.processData(data);

                }
            );
        }
    }

    processData(records:any) {

        let labels = [];
        let outputData = [];

       // let count = 0;

        //loop though records
        for (let entry of records) {
            //if(count < 20) { //temporey limit for testing and waiting for api fix
          outputData.push(entry.resulttime);
                labels.push(entry.date);
              //  count++;
            //}
        }

        this.lineChartLabels = labels;
        this.lineChartData = [{data: outputData}];
        console.log(this.lineChartData);
        this.refresh_chart();

    }

    //https://stackoverflow.com/questions/42629819/ng2-charts-update-labels-and-data
    refresh_chart() {
        setTimeout(() => {
            if (this.chart && this.chart.chart && this.chart.chart.config) {
                console.log('refresh');
                this.chart.chart.config.data.labels = this.lineChartLabels;
                //this.chart.chart.config.data.datasets = this.lineChartData;
                this.chart.chart.update();
            }
        });
    }


    // lineChart
    //https://github.com/chartjs/Chart.js/issues/2791#issuecomment-237690528
    public lineChartData:Array<any> = [
        {data: [] }

    ];
    public lineChartLabels:Array<any> = [];
    public lineChartOptions:any = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    userCallback: function(v) { return new Date(v).toISOString().substr(14, 8) },
                    //stepSize: 30 * 60
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    return "Tijd : "+new Date(tooltipItem.yLabel).toISOString().substr(14, 8)
                }
            }
        }
    };

    public lineChartLegend:boolean = false;
    public lineChartType:string = 'line';

}
