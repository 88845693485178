import {EventEmitter, Injectable} from '@angular/core';
import {AppService} from '../app.service';
import {Training} from './training';
import {AuthService} from '../auth/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Member} from '../shared/member';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TrainingService extends AppService {

  trainingChanged = new EventEmitter<Training[]>();
  trainings: Training[] = [];


  constructor(http: HttpClient, private authService: AuthService, private snackbar: MatSnackBar) {
    super(http);
  }

  fetchTrainingen() {
    return this.http.get(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/training')
      .subscribe(
        (data: Training[]) => {
          this.trainings = data;
          this.trainingChanged.emit(this.trainings);
        }
      );
  }

  fetchTrainingenOnDate(month: string, year: string) {
    return this.http.get(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/training?month=' + month + '&year=' + year)
      .subscribe(
        (data: Training[]) => {
          this.trainings = data;
          this.trainingChanged.emit(this.trainings);
        }
      );
  }

  getTraining(id: number) {
    return this.trainings[id];
  }

  getTrainings() {
    return this.trainings;
  }

  create(training: Training) {

    return this.http.post<Training>(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/training', training, this.HTTP_OPTIONS)
      .subscribe(
        (data: Training) => {
          this.trainings.unshift(data);
          this.trainingChanged.emit(this.trainings);
          this.snackbar.open('Training opgeslagen');
        },
        (error: any) => {
          this.snackbar.open('FOUT! training niet goed opgeslagen' + error.message, null, {duration: 5000});
          console.log('error', error);
        }
      );
  }

  edit(oldTraining: Training, training: Training) {
    console.log('new updated training', training);
    this.http.patch(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/training/' + oldTraining.id, training, this.HTTP_OPTIONS).subscribe(
      (data: Training) => {
        // set members
        // data.members = oldTraining.members;

        this.trainings[this.trainings.indexOf(oldTraining)] = data;
        this.trainingChanged.emit(this.trainings);
        this.snackbar.open('Training bijgewerkt');
      },
      (error: any) => {
        this.snackbar.open('FOUT! training niet goed opgeslagen' + error.message, null, {duration: 5000});
        console.log('error', error);
      }
    );
  }

  destroy(training: Training) {
    this.http.delete(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/training/' + training.id, this.HTTP_OPTIONS).subscribe(
      (data: any) => {
        this.trainings.splice(this.trainings.indexOf(training), 1);
        this.snackbar.open('Training verwijderd');
      },
      (error: any) => {
        this.snackbar.open('FOUT! training niet goed opgeslagen' + error.message, null, {duration: 5000});
        console.log('error', error);
      }
    );


  }

  members(training: Training) {
    this.http.post(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/training/' + training.id + '/members', training.members, this.HTTP_OPTIONS).subscribe(
      (data: Training) => {
        this.trainings[this.trainings.indexOf(training)] = data;
        this.trainingChanged.emit(this.trainings);
        this.snackbar.open('Aanwezigheid opgeslagen');
      },
      (error: any) => {
        this.snackbar.open('FOUT! training niet goed opgeslagen' + error.message, null, {duration: 5000});
        console.log('error', error);
      }
    );

  }

  removeMember(training: Training, member: Member) {
    this.http.delete(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/training/' + training.id + '/members/' + member.id, this.HTTP_OPTIONS).subscribe(
      (data: Training) => {
        this.trainings[this.trainings.indexOf(training)] = data;
        this.trainingChanged.emit(this.trainings);
        this.snackbar.open('Aanwezigheid opgeslagen');
      },
      (error: any) => {
        this.snackbar.open('FOUT! training niet goed opgeslagen' + error.message, null, {duration: 5000});
        console.log('error', error);
      }
    );

  }

  fetchExport(club_id : number, seperator = ';', year = 2019,trainer=0) {
    return this.http.get(`${this.API_URL}club/${club_id}/export/training?delimiter=${seperator}&year=${year}&trainer=${trainer}`, {
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type':  'text',
        'Authorization': 'Bearer ' + this.getApiToken()
      })
    });
  }

  fetchFile(club_id : number, file_id: number) {
    return this.http.get(`${this.API_URL}club/${club_id}/file/${file_id}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':  'application/octet-stream',
        'Authorization': 'Bearer ' + this.getApiToken()
      })
    });
  }

  signUpOut(training: Training,member:Member,signup:boolean) {

    let body = {
        'member': member.id,
        'action': (signup == true)? 'signup':'signout'
    }

    this.http.post(this.API_URL + 'club/' + this.authService.getSelectedClub() + '/training/' + training.id + '/signup', body , this.HTTP_OPTIONS).subscribe(
      (data: Training) => {
        this.trainings[this.trainings.indexOf(training)] = data;
        this.trainingChanged.emit(this.trainings);
        this.snackbar.open('Aanwezigheid opgeslagen');
      },
      (error: any) => {
        this.snackbar.open('FOUT! aanmelding niet goed opgeslagen' + error.message, null, {duration: 5000});
        console.log('error', error);
      }
    );

  }

}
