import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {News} from '../../../shared/news';
import {NewsService} from '../../../service/news.service';

@Component({
  selector: 'app-news-form-dialog',
  templateUrl: './news-form-dialog.component.html',
  styleUrls: ['./news-form-dialog.component.css']
})
export class NewsFormDialogComponent implements OnInit {

  news: News = new News('', '');

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<NewsFormDialogComponent>,
              public newsService: NewsService) {
  }

  ngOnInit() {
    if (this.data != null) {
      this.news = this.data;
    }

  }

  save() {
    if (this.news.id === undefined) {
      this.news.post_category_id = 0; // not implemented yet
      this.news.status = 'Published';

      this.newsService.create(this.news);

    } else {
      this.newsService.update(this.news);
    }

    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close('Pizza!');
  }
}
