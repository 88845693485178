import {EventEmitter, Injectable} from '@angular/core';
import {Club} from '../shared/club';
import {AppService} from '../app.service';
import {HttpClient} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ClubService extends AppService{

  clubs:Club[] = [];
  clubChanged = new EventEmitter<Club[]>();

  constructor(http: HttpClient, private snackbar: MatSnackBar) {
    super(http);
  }

  fetch() {
    this.http.get(this.API_URL+'club', this.HTTP_OPTIONS)
        .subscribe(
            (data:Club[]) => {
              this.clubs = data;
              this.clubChanged.emit(this.clubs);
            },
            (error:any) => this.fetch()
        );
  }

  save(role:Club) {
    return this.http.post(this.API_URL+'club',role, this.HTTP_OPTIONS)
        .subscribe(
            (data:Club) => {
              this.clubs.push(data);
              this.clubChanged.emit(this.clubs);
              this.snackbar.open("Club opgeslagen");

            },
            (error: any) => {
                this.snackbar.open("FOUT! club niet goed opgeslagen",null,{duration:10000});
                console.log("error",error)}
        );
  }

  edit(oldRole:Club, role:Club) {
    return this.http.patch(this.API_URL+'club/'+oldRole.id,role, this.HTTP_OPTIONS).subscribe(
        (data:Club) => {
          this.clubs[this.clubs.indexOf(oldRole)] = data;
          this.clubChanged.emit(this.clubs);
            this.snackbar.open("Club bijgewerkt");

          //console.log('patch',this.get(this.members.indexOf(oldMember)));
        },
        (error: any) => {
            this.snackbar.open("ERROR: fout met verzenden "+ error.message,null,{duration:10000});
            console.log("error",error)}
    );
  }

  delete(club:Club) {
    this.http.delete(this.API_URL+'club/' + club.id, this.HTTP_OPTIONS).subscribe(
        (data: any) => {
          this.clubs.splice(this.clubs.indexOf(club), 1);
        },
        (error: any) => {
            this.snackbar.open("ERROR: fout met verzenden "+ error.message,null,{duration:10000});
            console.log("error",error)}
    );
  }

  getAll() {
    return this.clubs;
  }

  get(index:number) {
    return this.clubs[index];
  }

}
