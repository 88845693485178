/**
 * Created by Patrick on 28-2-2017.
 */

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {HelperService} from "../service/helper.service";


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private helper: HelperService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.authService.isAuthenticated()) {

      if (state.url.includes('meet/show/')) {
        const urlSegments = state.url.split('/');
        console.log('found meet', urlSegments[urlSegments.length - 1].split('?')[0]); // redirect to external meet
        console.log('found meet', state.root.queryParams['overwrite']); // redirect to external meet
        window.location.href = `${this.helper.ServerUrl}/meet/${urlSegments[urlSegments.length - 1].split('?')[0]}?overwrite=state.root.queryParams['overwrite']`;
      }

      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

}
