<h1>
  Lid <span *ngIf="isNew">toevoegen</span> <span *ngIf="!isNew">bewerken</span>
</h1>

<mat-form-field class="full-width">
  <input matInput type="search" (keyup)="0" [(ngModel)]="searchValue"
         placeholder="Zoeken in swimtimes (auto aanvuller)"
         name="search" (keyup)="search($event)">
  <br/>

  <mat-list>
    <span *ngFor="let result of searchResults;let i = index">
        <mat-list-item
          (click)="memberSelect(result)"> {{result.aFirstName}} {{result.aNamePrefix}} {{result.aLastName}}
          - {{result?.tName}} </mat-list-item>
        <mat-divider></mat-divider>
      </span>
  </mat-list>
</mat-form-field>
<hr/>
<form #form="ngForm" (ngSubmit)="onSubmit(form.value)" fxLayout="column" fxLayoutGap="10px">

  <mat-form-field class="full-width">
    <mat-label>Geslacht</mat-label>
    <mat-select [(ngModel)]="selectedMember.sex" name="sex" required>
      <mat-option value="male">Man</mat-option>
      <mat-option value="female">Vrouw</mat-option>
    </mat-select>
  </mat-form-field>

  <div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field class="full-width">
      <input matInput placeholder="Voornaam" name="first_name" [(ngModel)]="selectedMember.first_name"
             required>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput placeholder="Voornaam" placeholder="achternaam"
             [(ngModel)]="selectedMember.name" name="name" required="required">
    </mat-form-field>
  </div>

  <mat-form-field class="full-width">
    <input matInput type="date" placeholder="Geboortedatum"
           [(ngModel)]="selectedMember.birthdate" name="birthdate" required>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput type="email" placeholder="Email"
           [(ngModel)]="selectedMember.email" name="email" >
    <mat-hint>Dit mail adres wordt gebruikt indien geen gebruiker koppeling is
      gevonden
    </mat-hint>
  </mat-form-field>
  <br/>
  <mat-form-field class="full-width" >
    <mat-label>Status</mat-label>

    <mat-select [(ngModel)]="selectedMember.status.id" name="status_id" required >
      <mat-option [value]="1">Lid</mat-option>
      <mat-option [value]="2">Introducee</mat-option>
      <mat-option [value]="3">Lid van andere afdeling</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width">
    <span matPrefix>+31 &nbsp;</span>
    <input matInput type="tel" placeholder="Telfoon nummer"
           [(ngModel)]="selectedMember.telephone" name="telephone">
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput type="number" placeholder="KNZB nummer"
           [(ngModel)]="selectedMember.knzb_number" name="knzb_number">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Opmerkingen</mat-label>
    <textarea matInput type="text" id="comments"
              [(ngModel)]="selectedMember.comments" name="comments">
        </textarea>
  </mat-form-field>

  <mat-form-field class="full-width" *ngIf="auth.hasPermission(['manage_member'])">
    <mat-label>Interne opmerking <small>(allen zichtbaar voor trainers)</small></mat-label>
    <textarea matInput type="text" id="internal_comment"
              [(ngModel)]="selectedMember.internal_comment" name="internal_comment">
        </textarea>
  </mat-form-field>

  <div>
    <label>Rol *</label>
    <mat-radio-group fxLayout="column" aria-label="Select an role" name="role_id" [(ngModel)]="selectedMember.role_id" >
      <mat-radio-button *ngFor="let role of roles" [value]="role.id" required> {{role.name}}</mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Swimtimes
      </mat-panel-title>
      <mat-panel-description>

      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field class="full-width">
      <input matInput type="number" placeholder="swim times id"
             [(ngModel)]="selectedMember.swim_times_id" name="swim_times_id">
    </mat-form-field>
  </mat-expansion-panel>


  <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">Opslaan</button>
</form>
