import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './auth/login/login.component';
import {TrainingDetailComponent} from './training/training-detail/training-detail.component';
import {AuthGuard} from './auth/auth.guard';
import {TrainingFormComponent} from './training/training-form/training-form.component';
import {TrainingComponent} from './training/training.component';
import {TrainingStartComponent} from './training/training-start.component';
import {MemberComponent} from './member/member.component';
import {MemberDetailComponent} from './member/member-detail/member-detail.component';
import {MemberFormComponent} from './member/member-form/member-form.component';
import {TrainingMemberComponent} from './training/training-member/training-member.component';
import {DashboardComponent} from './dashboard/dashboard/dashboard.component';
import {RecordDetailComponent} from './swimtimes/record-detail/record-detail.component';
import {OauthComponent} from './auth/oauth/oauth.component';
import {RecordFormComponent} from './record/record-form/record-form.component';
import {TrainingSignupComponent} from './training/training-signup/training-signup.component';
import { MemberImportComponent } from './member/member-import/member-import.component';

// @ts-ignore
/**
 * Created by Patrick on 7-3-2017.
 */

const routes: Routes = [
  {
    path: 'club',
    loadChildren: () => import('./club/club.module').then(m => m.ClubModule),
    // loadChildren: 'app/club/club.module#ClubModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    // loadChildren: 'app/user/user.module#UserModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'role',
    loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
    // loadChildren: 'app/roles/roles.module#RolesModule',
    // loadChildren: './club/club.module#ClubModule', (ERROR in Could not resolve module)
    canActivate: [AuthGuard]
  },
  {
    path: 'meet',
    loadChildren: () => import('./meet-signup/meet-signup.module').then(m => m.MeetSignupModule),
    // loadChildren: 'app/roles/roles.module#RolesModule',
    // loadChildren: './club/club.module#ClubModule', (ERROR in Could not resolve module)
    canActivate: [AuthGuard]
  },
  {
    path: 'group',
    loadChildren: () => import('./group/group.module').then(m => m.GroupModule),
    // loadChildren: 'app/roles/roles.module#RolesModule',
    // loadChildren: './club/club.module#ClubModule', (ERROR in Could not resolve module)
    canActivate: [AuthGuard]
  },
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},

  {path: 'auth/callback', pathMatch: 'full', component: OauthComponent},
  {path: 'auth/callback', pathMatch: 'full', component: OauthComponent},

  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},

  {
    path: 'training', canActivate: [AuthGuard], component: TrainingComponent, children: [
      {path: '', component: TrainingStartComponent},
      {path: 'new', component: TrainingFormComponent},
      {path: ':id', component: TrainingDetailComponent},
      {path: ':id/edit', component: TrainingFormComponent},
      {path: ':id/member', component: TrainingMemberComponent},
      {path: ':id/signup', component: TrainingSignupComponent},
    ]
  },

  {path: 'member', canActivate: [AuthGuard], component: MemberComponent},
  {path: 'member/import',canActivate: [AuthGuard], component: MemberImportComponent},
  {path: 'member/new', canActivate: [AuthGuard], component: MemberFormComponent},
  {path: 'member/:id', canActivate: [AuthGuard], component: MemberDetailComponent},
  {path: 'member/:id/edit', canActivate: [AuthGuard], component: MemberFormComponent},
  {path: 'member/:id/record/:style/:course', component: RecordDetailComponent},
  {path: 'member/:id/record/add', component: RecordFormComponent},

  // user module
  // {path: 'user-member', component: UserMemberComponent, canActivate: [AuthGuard]},
  // {path: 'dashboard/welkom', component: WelkomComponent, canActivate: [AuthGuard]},
  // {path: 'user-authorize-request', component: AuthorizeRequestComponent, canActivate: [AuthGuard]},

  // {
  //   path: 'user',
  //   loadChildren: './user/user.module#UserModule',
  //   canActivate: [AuthGuard]
  // },
];

export const routing = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
