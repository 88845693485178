import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MemberService} from '../../member/member.service';
import {StyleService} from '../../service/style.service';
import {Style} from '../../shared/style';
import {FormArray, FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {Record} from '../../shared/record';
import {ActivatedRoute} from '@angular/router';
import {Member} from '../../shared/member';
import {RecordService} from '../../service/record.service';
import { MatDialog } from '@angular/material/dialog';
import {StyleFormComponent} from '../../style/style-form/style-form.component';

@Component({
  selector: 'app-record-form',
  templateUrl: './record-form.component.html',
  styleUrls: ['./record-form.component.css']
})
export class RecordFormComponent implements OnInit {
  @ViewChild('record_input', {static: false}) record_input: ElementRef;
  @ViewChild('record_placeholder', {static: false}) record_placeholder: ElementRef;

  public styles: Style[] = [];
  styleControl = new FormControl();
  filteredOptions: Observable<Style[]>;
  showNewStyle = false;
  splitRecordArray = new FormArray([]);

  selectedMember: Member = null;
  subscription: Subscription;
  memberIndex: number;

  currentMask = '00:00:00.00';

  ResultMask = {
    'number': {'mask': '00000000', 'description': 'count'},
    'time': {'mask': '00:00:00.00', 'description': 'Record (hh:mm:ss:ms)'}
  };

  constructor(
    private memberService: MemberService,
    private styleService: StyleService,
    private route: ActivatedRoute,
    private recordService: RecordService,
    public dialog: MatDialog,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    this.memberIndex = this.route.snapshot.params['id'];
    this.selectedMember = this.memberService.getById(this.memberIndex);

    this.styleControl.valueChanges.subscribe(val => {
      switch(val.input_type) {

        case "number":
          // it works... but not realy
          //this.renderer.setAttribute(this.record_input.nativeElement,'mask',this.ResultMask.number.mask);
          this.currentMask = this.ResultMask.number.mask;
          this.record_placeholder.nativeElement.innerHTML = this.ResultMask.number.description;

          break;
        default:
        case "time":
          this.currentMask = this.ResultMask.time.mask;
          this.record_placeholder.nativeElement.innerHTML = this.ResultMask.time.description;
          break;
      }
    });

    // fetch member if changes happend
    this.memberService.memberChanged.subscribe((styles: Style[]) => {
      this.selectedMember = this.memberService.getById(this.memberIndex);
    });

    this.styleService.fetch();

    // on style model change
    this.styleService.styleEmitter.subscribe((styles: Style[]) => {
      this.styles = styles;
      this._filter('');
      this.showNewStyle = false;
    });

    // filter options
    this.filteredOptions = this.styleControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

  }

  // how to display by selectbox
  displayFn(style?: Style): string | undefined {
    return style ? style.distance.toString() + 'M ' + style.stroke_name : undefined;
  }

  onSubmit(object: any) {
    // // record and date
    // console.log(object);
    // this.timeMaskInputToMilisecond(object.record);
    // // style object
    // console.log(this.styleControl.value);
    // // split records
    // console.log(this.splitRecordArray.value);

    const record = new Record(
      this.timeMaskInputToMilisecond(object.record),
      this.convertSplitTimeArray(this.splitRecordArray.value),
      this.styleControl.value.id,
      object.date,
      this.selectedMember.id,
      false
    );

    //if it is a time record. convert to miliseconds
    if(this.styleControl.value.input_type == 'time') {
        record.record = this.timeMaskInputToMilisecond(object.record).toString();
    }

    this.recordService.create(record);

    //console.log(record);

    window.history.back();
  }

  onAddSplitTime() {
    this.splitRecordArray.push(
      new FormControl()
    );
  }

  onDeleteSplitTime(index) {
    this.splitRecordArray.removeAt(index);
  }

  convertSplitTimeArray(splittimes: string[]) {

    const newSplitTimes = [];

    for (const time of splittimes) {
      newSplitTimes.push(this.timeMaskInputToMilisecond(time));
    }

    return newSplitTimes;
  }

  /**
   * return input string to miliseconds for database
   * @param timeMaskInput
   */
  timeMaskInputToMilisecond(timeMaskInput: string) {
    const hour = parseInt(timeMaskInput.substring(0, 2), 0) * 60 * 10 * 10; // hour
    const minutes = parseInt(timeMaskInput.substring(2, 4), 0) * 60 * 10; // minuut
    const seconds = parseInt(timeMaskInput.substring(4, 6), 0) * 10; // seoonden
    const miliseconds = timeMaskInput.substring(6, 8); // millisecond

    console.log(hour + 'h' + minutes + 'm' + seconds + 's' + miliseconds, hour + minutes + seconds + miliseconds);

    return hour + minutes + seconds + miliseconds;
  }

  private _filter(value: string) {
    const filterValue = value.toString().toLowerCase();
    return this.styles.filter(style => (style.distance.toString() + ' ' + style.stroke_name.toLowerCase()).includes(filterValue));
  }

  DialogAddStyle() {
    let dialogRef = this.dialog.open(StyleFormComponent, {

    });
  }

}
