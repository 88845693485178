import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MemberService } from '../member.service';

@Component({
  selector: 'app-member-import',
  templateUrl: './member-import.component.html',
  styleUrls: ['./member-import.component.css']
})
export class MemberImportComponent implements OnInit {

  public seperators = [',', ';'];

  public fileFormControl = new FormControl('', [Validators.required]);
  public FileInput = false;
  public insertTypeInput = '0';
  public seperatorInput = ','
  public result = {'failed': [], 'created':[], 'updated':[] };

  displayedColumns: string[] = ['first_name', 'name', 'birthdate'];
  displayedColumnsWithError: string[] = ['first_name', 'name', 'birthdate', 'errors'];

  constructor(private memberService: MemberService, public snackbar: MatSnackBar) { }

  ngOnInit(): void {
  }

  handleFormInput(files: FileList) {
    if (files.length > 0) {

      const uploadfile = files.item(0);

      this.memberService.import(uploadfile,this.seperatorInput,this.insertTypeInput).subscribe(
        (data: any) => {
          this.result = data;
        },
        (error: any) => {
          this.snackbar.open('FOUT! import mislukt', null, {duration: 10000});
          console.log('error', error);
        }
      );

    }
  }

  errorsToList(jsonString: string) {

    let result = "";

    console.log(jsonString);

    const errorArray = JSON.parse(jsonString);

    for(let error in errorArray){
      result = result + `<li>${errorArray[error]}</li>`;
    }

    return result

  }

}
