import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from "../../../auth/auth.service";

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {

  @Output() sidenavClose = new EventEmitter();

  constructor(public auth: AuthService) { }

  ngOnInit() {
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  isAuth() {
    return this.auth.isAuthenticated();
  }

  hasRole(roles: Array<string>) {
    return this.auth.hasRole(roles);
  }

}
