<div style="width: 50vw">
  <h2>Export trainingen</h2>

  <mat-form-field class="example-full-width">
    <mat-label>Jaar</mat-label>
    <mat-select [(ngModel)]="selectYear">
      <mat-option *ngFor="let year of years" [value]="year">
        {{year}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <br/>
  <mat-label>Type</mat-label>
  <br/>
  <mat-radio-group fxLayoutGap="10px" fxLayoutAlign="column" aria-label="Type" [(ngModel)]="selectType">
    <mat-radio-button value=";">CSV (Windows)</mat-radio-button>
    <mat-radio-button value=",">CSV (Mac/Linux)</mat-radio-button>
  </mat-radio-group>

  <br/>
  <mat-label>Export van group</mat-label>
  <br/>
  <mat-radio-group fxLayoutGap="10px" fxLayoutAlign="column" aria-label="Type" [(ngModel)]="selectGroup">
    <mat-radio-button [value]="0">Deelnemers</mat-radio-button>
    <mat-radio-button [value]="1">Trainers</mat-radio-button>
  </mat-radio-group>

  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="exportTraining()">Export</button>
    <button mat-button (click)="closeDialog()">Close</button>
  </div>
</div>
