import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {BehaviorSubject} from 'rxjs';
import {UserService} from '../user/user.service';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private userService: UserService) {

    if ('Notification' in window && navigator.serviceWorker) { //@TODO not working animore https://github.com/angular/angularfire/blob/master/docs/messaging/messaging.md
      // this.angularFireMessaging.messages.subscribe(
      //   (_messaging) => {
      //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      //   }
      // );
    }
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(token) {
    console.log('token update');
    // we can change this function to request our backend service
    this.userService.addPushToken(token).subscribe(
      (data: any) => {
        console.log('registerd');
      }
    );
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.updateToken(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  unsubscribeToken() {
    this.angularFireMessaging.getToken.subscribe(
      (token) => {

        this.userService.removePushToken(token).subscribe(
          (data: any) => {
            this.angularFireMessaging.deleteToken(token).subscribe(
              (data: any) => {
                console.log('token removed');
              }
            );
          }
        );
      }
    );


  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        console.log('new message received. ', payload);

        console.log(payload.notification);

        // create notification object and send it to the user
        const notification = new Notification(
          payload.notification.title,
          {
            body: payload.notification.body,
            icon: payload.notification.icon,
          });

        notification.onclick = function () {
          window.open(payload.notification.click_action, '_blank');
        };

        this.currentMessage.next(payload);

      });
  }
}
