import {Component, OnInit} from '@angular/core';
import {Style} from '../../shared/style';
import {StyleService} from '../../service/style.service';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-style-form',
  templateUrl: './style-form.component.html',
  styleUrls: ['./style-form.component.css']
})
export class StyleFormComponent implements OnInit {

  constructor(private styleService: StyleService,
              public dialogRef: MatDialogRef<StyleFormComponent>) {
  }

  ngOnInit() {
  }


  onSubmit(object: Style) {
    object.open = false;
    this.styleService.create(object);
    this.dialogRef.close();
  }

}
