export const environment = {
  production: true,
  version: '1.1.12',
  APP_URL: 'https://app.swimhub.nl/',
  BASE_URL: 'https://api.swimhub.nl/',
  API_URL: 'https://api.swimhub.nl/api/',
  APP_SERCRET: 'hd9Prt7ppHwNhQphhV5u1XNj6EBRbuKb6x7x4HYx',
  APP_ID: '1',

  firebase: {
    apiKey: "AIzaSyBLtp3LbQ_6xwi_jYnFvpY8AnZMDiSU0BA",
    authDomain: "swimhub-6ca97.firebaseapp.com",
    databaseURL: "https://swimhub-6ca97.firebaseio.com",
    projectId: "swimhub-6ca97",
    storageBucket: "swimhub-6ca97.appspot.com",
    messagingSenderId: "484615981084",
    appId: "1:484615981084:web:76d6757d558118e9cd77d9"

  }
};
