<!--<header>
  <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
  <div class="alert alert-warning" role="alert" *ngIf="!networkStatus">
    Deze (web) app heeft een active internet verbinding nodig om te kunnen functioneren.
  </div>

</header>

<main class="container-fluid">
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
  </mat-sidenav>
  <router-outlet></router-outlet>

</main>

<footer class="container-fluid">
  <small class="float-right"><a target="_blank" href="/assets/privacy_statement.txt" title="privacy statement">privacy
    statement</a> | Powered by<a target="_blank" href="https://patrickdevelopment.nl"> Patrick Development</a> &copy;2019
  </small>
</footer>
-->
<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <header>
      <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
      <div class="alert alert-warning" role="alert" *ngIf="!networkStatus">
        Deze (web) app heeft een active internet verbinding nodig om te kunnen functioneren.
      </div>
    </header>
    <!--in here all the content must reside. We will add a navigation header as well-->
    <main class="container-fluid">
      <router-outlet></router-outlet>

      <footer class="container-fluid">
        <small class="float-right">
          Versie {{version}} |
          <a target="_blank" href="https://api.swimhub.nl/swimhub-algemene-voorwaarden.pdf" title="privacy statement">Algmene voorwaarden</a> |
          <a target="_blank" href="/assets/privacy_statement.txt" title="privacy statement">privacy statement</a>
          | Powered by<a target="_blank" href="https://patrickdevelopment.nl"> Patrick Development</a>
          &copy;2021
        </small>
      </footer>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>



