import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {User} from '../../shared/user';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.css']
})
export class OauthComponent implements OnInit {

  private code; // client token
  private redirectTo = ['/dashboard'];
  public status_message;

  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router, private snackbar: MatSnackBar) {
  }

  ngOnInit() {

    this.status_message = 'Moment geduld u wordt ingelogd....';

    this.route
      .queryParams
      .subscribe(params => {

        if (params.code) {
          this.code = params.code;
          // console.log('client secret code', this.code);

          this.authService.oauth2GetAppToken(this.code)
            .subscribe( (data : object) => {
              // this.authService.storeApiToken(data['access_token']);

              // for new security
              // @ts-ignore
              data.login_time = String(Date.now());
              localStorage.setItem('_a', window.btoa(JSON.stringify(data)));

              this.authService.refreshProfile();

              // when user profile is loaded redirect
              this.authService.authChanged.subscribe(
                (user: User) => {
                  this.snackbar.open('Login Geslaagd');

                  this.router.navigate(this.redirectTo);
                  this.status_message = '';
                }
              );
            });

        } else {
          // Implicit Grant Tokens mode
          if (this.router.url.search('#access_token') > 0) {

            const tokenObj = {
              login_time : String(Date.now())
            };

            const urlParts = this.router.url.split('#')[1];

            for (const part of urlParts.split('&')) {
              console.log(part); // 1, "string", false

              const urlPart = part.split('=');

              tokenObj[urlPart[0]] = urlPart[1];

            }

            console.log('object', tokenObj);

            localStorage.setItem('_a', window.btoa(
              JSON.stringify(tokenObj)
            ));
          }

          console.log('client not found');
          this.status_message = 'geen toegangs code aangetroffen probeer het opnieuw';
        }
      });

  }

}
