import {Injectable, EventEmitter} from '@angular/core';
import {AppService} from "../app.service";
import {User} from "../shared/user";
import {Member} from "../shared/member";
import {HttpClient} from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class UserService extends AppService {

    constructor(http:HttpClient,private snackbar:MatSnackBar) {
        super(http);

    }

    private users:User[] = [];
    public userChange = new EventEmitter<User[]>();

    fetch() {
        this.setHTTPOptions();
        this.http.get<User[]>(this.API_URL+'user',this.HTTP_OPTIONS).subscribe(
            (user : User[]) => {
                this.users = user;
                this.userChange.emit(this.users);
            }
        );
    }

    getAll() {
        return this.users
    }

    get(index:number) {
        return this.users[index];
    }

    save(user:User) {
        return this.http.post(this.API_URL+'user',user,this.HTTP_OPTIONS)
            .subscribe(
                (data:User) => {
                    this.users.push(data);
                    this.userChange.emit(this.users);
                    this.snackbar.open('Gebruiker opgeslagen');
                },
                (error: any) => {
                    this.snackbar.open("FOUT! gebruiker niet goed opgeslagen",null,{duration:5000});
                    console.log("error",error)}
            );
    }

    edit(oldUser:User,user:User) {
        return this.http.patch(this.API_URL+'user/'+oldUser.id,user,this.HTTP_OPTIONS).subscribe(
            (data:User) => {
                this.users[this.users.indexOf(oldUser)] = data;
                this.userChange.emit(this.users);
                this.snackbar.open('Gebruiker bijgewerkt');
            },
            (error: any) => {
                this.snackbar.open("FOUT! gebruiker niet goed opgeslagen",null,{duration:5000});
                console.log("error",error)}
        );
    }

    delete(user:User) {
        this.users.splice(this.users.indexOf(user), 1);
    }

    addMember(member:Member) {
        return this.http.post(this.API_URL+'user/member',{member_id:member.id},this.HTTP_OPTIONS);
    }

    updateMember(user:User,member:Member,authorised:number) {
        return this.http.patch(this.API_URL+'user/'+user.id+'/member',{member_id:member.id,authorised:authorised},this.HTTP_OPTIONS);
    }

    deleteMember(member:Member) {
        return this.http.delete(this.API_URL+'user/member/'+member.id,this.HTTP_OPTIONS);
    }

    addPushToken(token:string) {
        return this.http.post(this.API_URL+'user/pushToken',{token:token},this.HTTP_OPTIONS);
    }

    removePushToken(token:string) {
        return this.http.delete(this.API_URL+'user/pushToken/'+token,this.HTTP_OPTIONS);
    }


}
