import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';
import {AppModule} from './app/app.module';

if (environment.production) {
  enableProdMode();
}

// // use the require method provided by webpack
// declare const require;
// // we use the webpack raw-loader to return the content as a string
// const translations = require('raw-loader!./locale/messages.xlf').default;


platformBrowserDynamic().bootstrapModule(AppModule);

// platformBrowserDynamic().bootstrapModule(AppModule, {
//   providers: [
//     {provide: TRANSLATIONS, useValue: translations},
//     {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}
//   ]
// });
